import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import SelectOptions from '../../../../../../../components/SelectOptions'

import CreateUserModal from './components/CreateUserModal'

import styles from './styles.module.css'

const Options = ({ filters, setFilters, maxItems, setMaxItems }) => {
  const [open, setOpen] = useState(false)

  const handleSelectChange = newValue => {
    setMaxItems(newValue)
  }

  const renderRole = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Rol</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.role}
          name="type"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todas</MenuItem>
          <MenuItem value="SAURON">Sauron</MenuItem>
          <MenuItem value="CONSULTANT">Consultor</MenuItem>
          <MenuItem value="MASTER">Máster</MenuItem>
          <MenuItem value="BASIC">Básico</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={6} sm={5}>
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda"
          variant="outlined"
          name="search"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  const renderButton = () => (
    <Grid item xs={12} sm={2}>
      <Button
        variant="contained"
        className={styles.btnCreate}
        onClick={() => setOpen(true)}>
        Nuevo usuario
      </Button>
    </Grid>
  )

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        {renderRole()}
        {renderSearch()}
        {
          <SelectOptions
            value={maxItems}
            onChange={handleSelectChange}
            name="Cantidad usuarios"
          />
        }
        {renderButton()}
      </Grid>
      <CreateUserModal open={open} setOpen={setOpen} />
    </>
  )
}

export default Options
