import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  Divider,
  Modal,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import isEmail from 'validator/lib/isEmail'

import ApplicationForm from './components/ApplicationForm'

import styles from './styles.module.css'

const CREATE_APPLICATION = gql`
  mutation CreateApplication($input: ApplicationInput!) {
    createApplication(input: $input) {
      _id
    }
  }
`

const ApplicationModal = ({
  vacancyId,
  vacancyName,
  vacancyQuestions,
  open,
  setOpen,
  reload,
}) => {
  const [values, setValues] = useState({ answers: {} })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [createApplication, { loading }] = useMutation(CREATE_APPLICATION)

  const validateFields = () => {
    const {
      applierName,
      applierPhone,
      applierEmail,
      applierExpectedSalary,
    } = values

    if (
      !(applierName && applierPhone && applierEmail && applierExpectedSalary)
    ) {
      setError('Ingrese todos los campos')
      return false
    }

    if (!isEmail(applierEmail)) {
      setError('Correo electrónico inválido')
      return false
    }

    if (applierExpectedSalary < 0) {
      setError('La expectativa de renta no puede ser menor a 0')
      return false
    }

    return true
  }

  const handleSubmit = async () => {
    if (!validateFields()) return

    try {
      await createApplication({
        variables: {
          input: {
            ...values,
            vacancy: vacancyId,
            status: 'PENDING_CONTACT',
            applierExpectedSalary: parseFloat(values.applierExpectedSalary),
            answers: Object.keys(values.answers).map(el => {
              return {
                question: el,
                answer: values.answers[el],
              }
            }),
          },
        },
      })
      setSuccess('El candidato se ha registrado con éxito')
      reload()
      setOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description">
        <Paper className={styles.paper}>
          <Typography
            className={
              styles.title
            }>{`Agregar candidato para: ${vacancyName}`}</Typography>
          <div className={styles.formWrapper}>
            <Typography style={{ marginBottom: 20 }}>
              <b>Información</b>
            </Typography>
            <Divider className={styles.divider} />
            <ApplicationForm
              values={values}
              setValues={setValues}
              questions={vacancyQuestions || []}
            />
            <div className={styles.btnGroup}>
              <Button
                className={styles.cancelBtn}
                onClick={() => setOpen(false)}>
                Cerrar
              </Button>
              <Button className={styles.applyBtn} onClick={handleSubmit}>
                Aplicar
              </Button>
            </div>
          </div>
          {loading ? <CircularProgress /> : null}
        </Paper>
      </Modal>
      <Snackbar
        open={!!error}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        onClose={() => setSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success">{success}</Alert>
      </Snackbar>
    </>
  )
}

export default ApplicationModal
