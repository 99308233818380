import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'

import { Context } from '../../../../../../context'

import styles from './styles.module.css'

const messagesDictionary = {
  LOADING: 'Cargando...',
  NO_VOTES:
    'Antes de visualizar el ranking, deberás votar por todos los candidatos',
  ALL_VOTES:
    'A continuación podrás ver el ranking de candidatos. Recuerda que el ranking definitivo lo podrás ver cuando los demás votantes hayan participado',
  LESS_VOTES:
    'Aún no has votado por todos los candidatos. Si no votas por la totalidad de ellos, el resultado del ranking no será preciso',
  ALL_ACCESS:
    'A continuación, podrás visualizar el ranking según el voto de los participantes',
}

const messagesEnum = {
  NO_VOTES: 'NO_VOTES',
}

const RankingMessage = ({ ranking, onAccept }) => {
  const { goBack } = useHistory()
  const userInfo = useContext(Context)
  const [message, setMessage] = useState('LOADING')

  useEffect(() => {
    const votes = [...ranking] || []
    const userVotes = votes.filter(
      vote => !!vote.videoResults.find(v => v.userid === userInfo._id),
    )
    if (userInfo.role === 'SAURON') return setMessage('ALL_ACCESS')
    if (userVotes.length === 0) return setMessage('NO_VOTES')
    if (userVotes.length === votes.length) return setMessage('ALL_VOTES')
    if (userVotes.length < votes.length) return setMessage('LESS_VOTES')
  }, [ranking, userInfo])

  return (
    <div className={styles.messageWrapper}>
      <Typography>
        <b>{messagesDictionary[message]}</b>
      </Typography>
      <Button
        className={styles.acceptBtn}
        onClick={message !== messagesEnum.NO_VOTES ? onAccept : goBack}>
        Aceptar
      </Button>
    </div>
  )
}

RankingMessage.defaultProps = {
  ranking: [],
}

export default RankingMessage
