import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import styles from './styles.module.css'

const Questions = ({ values, setValues, questions }) => {
  const handleChange = event => {
    setValues({
      ...values,
      answers: { ...values.answers, [event.target.name]: event.target.value },
    })
  }

  return (
    <>
      {questions.map(el => {
        return (
          <div key={el._id}>
            <Typography variant="body1">
              <b>{el.text}</b>
            </Typography>
            {el.type === 'MULTIPLE_CHOICE' ? (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="options"
                  name={el.text}
                  value={values.answers[el.text] || ''}
                  onChange={handleChange}>
                  {el.options.map(option => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : (
              <FormControl component="fieldset" className={styles.formControl}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name={el.text}
                  fullWidth
                  onChange={handleChange}
                />
              </FormControl>
            )}
          </div>
        )
      })}
    </>
  )
}

Questions.defaultProps = {
  questions: []
}

export default Questions
