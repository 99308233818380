import React, { forwardRef, useState } from 'react'
import {
  Button,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Questions from './components/Questions'

import styles from './styles.module.css'

const ApplicationForm = forwardRef(({ values, setValues, questions }, ref) => {
  const [cvButtonLabel, setCvButtonLabel] = useState('Subir CV')

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    })
  }

  const handleInputFileChange = event => {
    setCvButtonLabel(event.target.files[0].name)
  }

  return (
    <>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Nombre"
          variant="outlined"
          name="applierName"
          fullWidth
          helperText="Escribe el nombre por el que nos podemos referir a ti"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Teléfono"
          variant="outlined"
          name="applierPhone"
          fullWidth
          onChange={handleChange}
          placeholder="56999999999"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddIcon className={styles.plusIcon} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Correo electrónico"
          variant="outlined"
          name="applierEmail"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Expectativa de renta"
          helperText="En la moneda del país de la vacante. Sin puntos (.) ni comas (,)"
          variant="outlined"
          name="applierExpectedSalary"
          fullWidth
          type="number"
          placeholder="Ej: 1200000"
          onChange={handleChange}
        />
      </FormControl>
      <input
        className={styles.fileInput}
        id="cv-button"
        type="file"
        onChange={handleInputFileChange}
        ref={ref}
        accept=".doc,.docx, application/msword, application/pdf, image/*"
      />
      <label htmlFor="cv-button">
        <Button
          variant="contained"
          component="span"
          className={styles.cvButton}>
          {cvButtonLabel}
        </Button>
      </label>

      {questions.length > 0 ? (
        <>
          <Typography className={styles.label}>
            <b>Cuestionario</b>
          </Typography>
          <Divider className={styles.divider} />

          <Questions
            values={values}
            setValues={setValues}
            questions={questions}
          />
        </>
      ) : null}
    </>
  )
})

ApplicationForm.defaultProps = {
  questions: [],
}

export default ApplicationForm
