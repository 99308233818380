import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'

import styles from './styles.module.css'

const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CompanyInput!) {
    createCompany(input: $input) {
      _id
    }
  }
`

const CreateCompanyModal = ({ open, setOpen, handleComplete }) => {
  const [values, setValues] = useState({})

  const [createCompany, { loading }] = useMutation(CREATE_COMPANY, {
    update(cache, { data: { createCompany } }) {
      cache.modify({
        fields: {
          companies(existingCompanies = []) {
            const newCompanyRef = cache.writeFragment({
              data: createCompany,
              fragment: gql`
                fragment NewCompany on Company {
                  _id
                  name
                  plan
                }
              `,
            })
            return [...existingCompanies, newCompanyRef]
          },
        },
      })
    },
  })

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleClick = async () => {
    try {
      await createCompany({ variables: { input: values } })
      setOpen(false)
      handleComplete()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper className={styles.paper}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Crea una empresa
        </Typography>
        <FormControl component="fieldset" className={styles.formControl}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Nombre"
            name="name"
            onChange={handleChange}
            variant="outlined"
            disabled={loading}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          component="fieldset"
          className={styles.formControl}>
          <InputLabel id="plan-select-label">Plan</InputLabel>
          <Select
            fullWidth
            label="Plan"
            labelId="plan-select-helper-label"
            onChange={handleChange}
            disabled={loading}
            name="plan"
            value={values.plan || ''}>
            <MenuItem value="CORP">Corporativo</MenuItem>
            <MenuItem value="PYME">Pyme</MenuItem>
            <MenuItem value="ZERO">Zero</MenuItem>
            <MenuItem value="FULL">Full</MenuItem>
          </Select>
        </FormControl>
        <Button
          color="primary"
          onClick={handleClick}
          style={{ float: 'right' }}
          disabled={loading}
          variant="contained">
          Crear
        </Button>
        {loading ? <CircularProgress /> : null}
      </Paper>
    </Modal>
  )
}

export default CreateCompanyModal
