import React from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ProtectedComponent from '../../../components/ProtectedComponent'
import { CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE } from '../../../userRoles'

const areas = [
  { label: 'Finanzas', value: 'FINANCE' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Administración', value: 'ADMINISTRATION' },
  { label: 'Logística', value: 'LOGISTICS' },
  { label: 'IT', value: 'IT' },
  { label: 'Construcción', value: 'CONSTRUCTION' },
  { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
  { label: 'RRHH', value: 'HR' },
  { label: 'Operaciones', value: 'OPERATIONS' },
  { label: 'Otro', value: 'OTHER' },
]

const seniorityLevels = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  editBtn: {
    width: '169px',
    backgroundColor: '#1B2124 !important',
    color: '#ffffff !important',
    textTransform: 'none! important',
  },
}))

const Information = ({
  setValues,
  values,
  companies,
  users,
  handleEdit,
  editButtonDisabled,
}) => {
  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const classes = useStyles()
  return (
    <Paper style={{ padding: 30 }}>
      <FormControl component="fieldset" className={classes.formControl}>
        <TextField
          id="outlined-basic"
          label="Título de la vacante"
          variant="outlined"
          name="title"
          defaultValue={values.title}
          fullWidth
          onChange={onChange}
        />
      </FormControl>
      <ProtectedComponent allowedRoles={[MASTER_ROLE, SAURON_ROLE]}>
        <FormControl
          component="fieldset"
          variant="outlined"
          className={classes.formControl}>
          <InputLabel id="user-label">Encargado</InputLabel>
          <Select
            id="user-label"
            labelId="user-label"
            name="users"
            fullWidth
            value={values.users || []}
            multiple
            label="Encargado"
            onChange={onChange}>
            {users.map(user => (
              <MenuItem key={user._id} value={user._id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ProtectedComponent>
      <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
        <FormControl
          component="fieldset"
          variant="outlined"
          className={classes.formControl}>
          <InputLabel id="company-label">Empresa</InputLabel>
          <Select
            id="company-label"
            labelId="company-label"
            name="company"
            label="Empresa"
            value={values.company}
            fullWidth
            onChange={onChange}>
            {companies.map(company => (
              <MenuItem key={company._id} value={company._id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ProtectedComponent>
      <FormControl component="fieldset" className={classes.formControl}>
        <TextField
          id="outlined-basic"
          label="Ubicación"
          variant="outlined"
          name="location"
          defaultValue={values.location}
          fullWidth
          onChange={onChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl}>
        <TextField
          id="outlined-basic"
          label="Renta máxima"
          variant="outlined"
          name="maxIncome"
          defaultValue={values.maxIncome}
          fullWidth
          type="number"
          placeholder="Ej. 1200000"
          onChange={onChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl}>
        <TextField
          id="outlined-basic"
          label="Descripción"
          variant="outlined"
          name="description"
          defaultValue={values.description}
          multiline
          fullWidth
          onChange={onChange}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="outlined"
        className={classes.formControl}>
        <InputLabel id="company-label">Área</InputLabel>
        <Select
          id="outlined-basic"
          labelId="area-label"
          name="area"
          label="Área"
          value={values.area}
          fullWidth
          onChange={onChange}>
          {areas.map(area => (
            <MenuItem key={area.label} value={area.value}>
              {area.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        component="fieldset"
        variant="outlined"
        className={classes.formControl}>
        <InputLabel id="seniority-label">Nivel</InputLabel>
        <Select
          id="outlined-basic"
          labelId="seniority-label"
          name="seniorityLevel"
          label="Nivel"
          value={values.seniorityLevel}
          fullWidth
          onChange={onChange}>
          {seniorityLevels.map(seniorityLevel => (
            <MenuItem key={seniorityLevel.label} value={seniorityLevel.value}>
              {seniorityLevel.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {handleEdit ? (
        <ProtectedComponent
          allowedRoles={[CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={classes.formControl}>
            <InputLabel id="status-label">Estado</InputLabel>
            <Select
              id="status-label"
              labelId="status-label"
              name="status"
              value={values.status}
              fullWidth
              label="Estado"
              onChange={onChange}>
              <MenuItem value="VISIBLE">Activa</MenuItem>
              <MenuItem value="HIDDEN">Inactiva</MenuItem>
            </Select>
          </FormControl>
          <Button
            className={classes.editBtn}
            onClick={handleEdit}
            disabled={!editButtonDisabled}>
            Guardar Información
          </Button>
        </ProtectedComponent>
      ) : null}
    </Paper>
  )
}

export default Information
