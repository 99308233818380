import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import Pagination from '../../../../../../../components/Pagination'
import { getPageItems } from '../../../../../../../helpers/paginated'

import ModalMessage from './../../../../../../../components/MessageModal'
import LongMenu from './components/collapsibleButton'
import SelectUserType from './components/SelectUserType'

import styles from './styles.module.css'

const DISABLED_USER = gql`
  mutation disableUser($_id: ID!) {
    disableUser(_id: $_id) {
      _id
    }
  }
`
const ENABLED_USER = gql`
  mutation enableUser($_id: ID!) {
    enableUser(_id: $_id) {
      _id
    }
  }
`

const UsersList = ({
  users,
  setUsers,
  search,
  type,
  handleChangeRol,
  maxItems,
}) => {
  const { push } = useHistory()

  const options = [
    {
      name: 'Deshabilitar',
    },
    {
      name: 'Habilitar',
    },
  ]

  const [disableUser] = useMutation(DISABLED_USER)
  const [enableUser] = useMutation(ENABLED_USER)
  const [elements, setElements] = useState({})
  const [modalEditUserState, setModalEditUserState] = useState(false)
  const [textUserState, setTextUserState] = useState('deshabilitar')
  const [userSelectedState, setUserSelectedState] = useState(null)

  const enableOrDisabledUser = async () => {
    if (!userSelectedState) return
    if (userSelectedState.disabledAt)
      await enableUser({
        variables: {
          _id: userSelectedState._id,
        },
      })
    else
      await disableUser({
        variables: {
          _id: userSelectedState._id,
        },
      })

    setUsers(() => {
      const disabledAtData = !userSelectedState.disabledAt && new Date()
      const indexUserUpdated = users.map(el =>
        el._id === userSelectedState._id
          ? { ...el, disabledAt: disabledAtData }
          : el,
      )
      return indexUserUpdated
    })

    setUserSelectedState(null)
    setModalEditUserState(false)
    return
  }

  const handleModal = userAndOptions => {
    setUserSelectedState(userAndOptions)
    userAndOptions.disabledAt && setTextUserState('habilitar')
    if (!modalEditUserState) return setModalEditUserState(true)
  }

  const setPage = useCallback(
    (page = 1) => {
      const usersFiltered = users.filter(
        val =>
          val.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
          (type === 'ALL' ? true : type === val.role),
      )

      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(usersFiltered, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [maxItems, search, type, users],
  )

  useEffect(() => {
    setPage()
  }, [users, search, type, maxItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={styles.mainContentList}>
      {elements.exists ? (
        elements.items.map(users => (
          <Grid key={users._id} item xs>
            <Paper variant="elevation" square className={styles.paperList}>
              <Grid className={styles.infoVacancy}>
                <Typography component={'span'} className={styles.vacTitle}>
                  <b>
                    {users.name}
                    <strong className={styles.strongDisabled}>
                      {users.disabledAt && ' Deshabilitado'}
                    </strong>
                  </b>
                </Typography>
              </Grid>
              <Grid className={styles.options}>
                <Grid style={{ display: 'flex' }}>
                  <div className={styles.dropdown}>
                    <SelectUserType
                      defaultType={users.role}
                      handleRolChange={(role, callback) =>
                        handleChangeRol(users._id, { role }, callback)
                      }
                    />
                  </div>
                  <Button
                    className={styles.btn}
                    onClick={() => push(`/admin/profile/${users._id}`)}>
                    <ArrowForwardIosIcon />
                  </Button>
                  <LongMenu
                    options={options}
                    user={users}
                    handleModal={handleModal}></LongMenu>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))
      ) : (
        <Typography className={styles.notFoundText}>
          No se encontraron resultados
        </Typography>
      )}
      {elements.exists && renderPagination()}
      <ModalMessage
        open={modalEditUserState}
        handleClose={() => setModalEditUserState(false)}
        okText="Confirmar"
        handleConfirm={() => enableOrDisabledUser()}
        message={`¿Estás seguro que deseas ${textUserState} este usuario?`}
        title="Editar usuario"></ModalMessage>
    </Grid>
  )
}

export default UsersList
