import React from 'react'
import { Container, Grid } from '@material-ui/core'

import PageHeader from '../../../components/PageHeader'

import List from './components/List'

import styles from './styles.module.css'

const Main = () => {
  return (
    <>
      <PageHeader title="Vacantes" />
      <Container>
        <Grid
          container
          direction="column"
          spacing={3}
          className={styles.mainContentList}>
          <List />
        </Grid>
      </Container>
    </>
  )
}

export default Main
