import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'
import isEmail from 'validator/lib/isEmail'

import Loading from '../../../components/Loading'
import MessageModal from '../../../components/MessageModal'
import PageHeader from '../../../components/PageHeader'
import { Context } from '../../../context'
import { SAURON_ROLE } from '../../../userRoles'

import UserForm from './components/UserForm'
import UserTabs from './components/UserTabs'
import UserVacancies from './components/UserVacancies'

const USER = gql`
  query User($input: UserQueryInput!) {
    user(input: $input) {
      _id
      role
      name
      email
      phone
      position
      company {
        _id
        name
      }
    }
  }
`

const COMPANIES = gql`
  query Companies {
    companies {
      _id
      name
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($_id: ID!, $input: UpdateUserInput!) {
    updateUser(_id: $_id, input: $input) {
      _id
    }
  }
`

const Profile = () => {
  const userInfo = useContext(Context)
  const { userId } = useParams()
  const [user, setUser] = useState(null)
  const [values, setValues] = useState({})
  const [companies, setCompanies] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [error, setError] = useState('')
  const { loading: companiesLoading, data: companiesData } = useQuery(COMPANIES)
  const [updateUser, { loading: loadingUpdate }] = useMutation(UPDATE_USER)
  const { loading, data } = useQuery(USER, {
    variables: { input: { _id: userId } },
  })

  useEffect(() => {
    const user = get(data, 'user', {})
    const companies = get(companiesData, 'companies', [])
    const userInputs = { ...user }

    delete userInputs.__typename
    delete userInputs._id
    delete userInputs.role
    if (user.company)
      userInputs.company =
        userInfo.role === SAURON_ROLE ? user.company._id : user.company

    setValues(userInputs)
    setUser(user)
    setCompanies(companies)
  }, [data, companiesData, userInfo])

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const handleToggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const validateFields = () => {
    const { name, phone, email, company, position } = values

    if (!(name && phone && email && company && position)) {
      setError('Ingrese todos los campos')
      return false
    }

    if (!isEmail(email)) {
      setError('Correo electrónico inválido')
      return false
    }

    if (phone.length < 10 && phone.length > 15 && isNaN(phone)) {
      setError('Ingrese un número telefónico de entre 10 y 15 dígitos.')
      return false
    }

    return true
  }

  const handleUpdateUserInfo = async () => {
    if (!validateFields()) return

    try {
      await updateUser({
        variables: {
          _id: user._id,
          input: values,
        },
      })
      handleToggleModal()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PageHeader
        title={loading ? `Cargando información...` : user?.name}
        type="users"
      />
      {loading || companiesLoading ? (
        <Loading />
      ) : (
        <>
          <UserTabs
            selectedTab={selectedTab}
            handleChange={handleChange}
            role={user?.role}
          />
          {selectedTab === 0 ? (
            <UserForm
              companies={companies}
              values={values}
              setValues={setValues}
              handleSave={handleToggleModal}
            />
          ) : (
            <UserVacancies />
          )}
        </>
      )}
      <MessageModal
        open={modalOpen}
        handleClose={handleToggleModal}
        okText={loadingUpdate ? 'Guardando...' : 'Sí'}
        cancelText="No"
        title="Alerta: Editar usuario"
        message="¿Desea guardar los cambios realizados a este usuario?"
        handleConfirm={handleUpdateUserInfo}
      />
      <Snackbar
        open={!!error}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  )
}

export default Profile
