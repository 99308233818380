/* eslint-disable react/no-array-index-key */
import React from 'react'
import ReactPlayer from 'react-player'
import {
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

import ProtectedComponent from '../../../../../../../../../../components/ProtectedComponent'
import {
  CONSULTANT_ROLE,
  SAURON_ROLE,
} from '../../../../../../../../../../userRoles'

import ApplicantCV from './components/ApplicantCV'
import CommentsSection from './components/CommentsSection'
import Questions from './components/Questions'

import styles from './styles.module.css'

const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN

const applicationStatus = {
  APPLICANT_PRESENTED: 'APPLICANT_PRESENTED',
  HIRED: 'HIRED',
  TO_UNLINK: 'TO_UNLINK',
  UNLINKED: 'UNLINKED',
}

const statusDictionary = {
  PENDING_CONTACT: 'Por Contactar',
  VIDEO_REQUESTED: 'Video Solicitado',
  VIDEO_SENT: 'Video Recibido',
  APPLICANT_PRESENTED: 'Candidato Presentado',
  HIRED: 'Contratado',
  TO_UNLINK: 'Por Desvincular',
  UNLINKED: 'Desvinculado',
}

const PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: 'nodownload',
    },
  },
}

const ApplicationInfo = ({
  application,
  handleInterviewsComments,
  user,
  questions,
}) => {
  const { APPLICANT_PRESENTED, HIRED, TO_UNLINK, UNLINKED } = applicationStatus
  const {
    _id,
    applierPhone,
    applierExpectedSalary,
    video,
    previousApplications,
    interviews,
    resume,
    status,
    applierEmail,
    answers,
  } = application
  const validApplications = previousApplications?.filter(
    application =>
      application.status !== TO_UNLINK || application.status !== UNLINKED,
  )

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container direction="column">
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Correo Electrónico"
              name="applierEmail"
              value={applierEmail}
              disabled
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Número de Teléfono"
              name="phone"
              value={`${applierPhone}`}
              disabled
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <a href={`tel:+${applierPhone}`} target="_blank">
                      <CallIcon className={styles.Icons} />
                    </a>
                    <a href={`https://wa.me/${applierPhone}`} target="_blank">
                      <WhatsAppIcon className={styles.Icons} />
                    </a>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Expectativa de Renta"
              name="expected_income"
              value={applierExpectedSalary}
              disabled
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid>
          <ApplicantCV cvURL={resume} />
        </Grid>
        <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
          {validApplications && validApplications.length > 0 && (
            <>
              <Typography className={styles.textSection}>
                <b>Historial de Postulaciones</b>
              </Typography>
              <Grid container direction="column">
                {validApplications.map(
                  ({ vacancy, status }, index) =>
                    vacancy && (
                      <div key={index}>
                        <FormControl
                          component="fieldset"
                          className={styles.inputGroup}>
                          <TextField
                            id="outlined-basic"
                            label="Cargo"
                            name="maximun_rent"
                            value={`${vacancy.title} - ${statusDictionary[status]}`}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </div>
                    ),
                )}
              </Grid>
            </>
          )}
        </ProtectedComponent>
        {video && (
          <>
            <Typography className={styles.textSection}>
              <b>Video</b>
            </Typography>
            <div>
              <ReactPlayer
                className={styles.videoPlayer}
                url={`${CDN_URL}/${video}`}
                config={PLAYER_CONFIG}
                controls
              />
            </div>
          </>
        )}
      </div>
      <Divider orientation="vertical" />
      <div style={{ width: '100%' }}>
        <Grid>
          <Questions values={questions} answers={answers} />
        </Grid>

        {(status === APPLICANT_PRESENTED || status === HIRED) && (
          <div style={{ width: '100%' }}>
            <Typography className={styles.textSection}>
              <b>Agendar entrevista</b>
            </Typography>
            <Grid>
              <CommentsSection
                comments={interviews}
                user={user}
                handleAddComment={interviewsUpdated =>
                  handleInterviewsComments(_id, {
                    interviews: [...interviewsUpdated],
                  })
                }
              />
            </Grid>
          </div>
        )}
      </div>
    </>
  )
}

export default ApplicationInfo
