import React from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import styles from './styles.module.css'

const areas = [
  { label: 'Finanzas', value: 'FINANCE' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Administración', value: 'ADMINISTRATION' },
  { label: 'Logística', value: 'LOGISTICS' },
  { label: 'IT', value: 'IT' },
  { label: 'Construcción', value: 'CONSTRUCTION' },
  { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
  { label: 'RRHH', value: 'HR' },
  { label: 'Operaciones', value: 'OPERATIONS' },
  { label: 'Otro', value: 'OTHER' },
]

const positions = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]
const handleAddKeywords = params =>
  params.split(',').reduce((acc, el) => [...acc, el.trimStart().trimEnd()], [])

const Options = ({
  filters,
  setFilters,
  handleSearch,
  loading,
  maxItems,
  setMaxItems,
}) => {
  const renderArea = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-area-label">Área</InputLabel>
        <Select
          labelId="role-select-area-label"
          id="area-select"
          name="area"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todas</MenuItem>
          {areas.map(area => (
            <MenuItem key={area.value} value={area.value}>
              {area.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const renderRole = () => (
    <Grid item xs={6} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-position-label">Cargo</InputLabel>
        <Select
          labelId="role-position-label"
          id="position-select"
          value={filters.position}
          name="position"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todas</MenuItem>
          {positions.map(area => (
            <MenuItem key={area.value} value={area.value}>
              {area.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const renderSalary = () => (
    <Grid item xs={5} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que pidan un monto igual o menor al monto que escribas"
        placement="top">
        <FormControl component="fieldset">
          <TextField
            id="outlined-basic"
            label="Renta máxima"
            variant="outlined"
            name="expectedSalary"
            type="number"
            fullWidth
            placeholder="Ej. 1200000"
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: parseFloat(event.target.value),
              })
            }
          />
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={5} sm={2}>
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda por palabras clave"
          variant="outlined"
          name="keywords"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: handleAddKeywords(event.target.value),
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  const renderItems = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="items-select-label">Cantidad espontáneos</InputLabel>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={maxItems}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={event => setMaxItems(event.target.value)}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        className={styles.searchButton}
        disabled={loading}
        onClick={handleSearch}>
        Buscar
      </Button>
    </div>
  )

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        {renderArea()}
        {renderRole()}
        {renderSalary()}
        {renderSearch()}
        {renderItems()}
      </Grid>
      {renderButton()}
    </>
  )
}

export default Options
