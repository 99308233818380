import React, { useCallback, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import Pagination from '../../../../../components/Pagination'
import { getPageItems } from '../../../../../helpers/paginated'

import ProfileInfo from './components/ProfileInfo'
import ProfilesMenu from './components/ProfilesMenu'

import styles from './styles.module.css'

const ApplicantsList = ({ profiles, handleToApply, maxItems }) => {
  const [elements, setElements] = useState({})

  const setPage = useCallback(
    (page = 1) => {
      if (!profiles) return
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(profiles, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [maxItems, profiles],
  )

  useEffect(() => {
    setPage()
  }, [profiles, maxItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      {elements.exists ? (
        elements.items.map(profile => (
          <>
            <Accordion key={profile._id} className={styles.applicationListItem}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${profile._id}-content`}
                id={`${profile._id}-header`}>
                <div className={styles.containerText}>
                  <Typography className={styles.textName}>
                    <b>{profile.name}</b>
                  </Typography>
                </div>
                <div>
                  <ProfilesMenu handleToApply={() => handleToApply(profile)} />
                </div>
              </AccordionSummary>
              <AccordionDetails className={styles.infoWrapper}>
                <ProfileInfo profile={profile} />
              </AccordionDetails>
            </Accordion>
          </>
        ))
      ) : (
        <Typography className={styles.message}>
          <b>Por favor, defina los filtros para realizar la busqueda.</b>
        </Typography>
      )}
      {elements.exists && renderPagination()}
    </>
  )
}

export default ApplicantsList
