import React from 'react'
import { IconButton,Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import styles from './styles.module.css'

const ProfilesMenu = ({ handleToApply }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleApply = () => {
    handleToApply()
    handleClose()
  }

  return (
    <div className={styles.menu}>
      <IconButton
        aria-label="more"
        aria-controls="apply-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="apply-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleApply}>Enviar a vacante</MenuItem>
      </Menu>
    </div>
  )
}

export default ProfilesMenu
