import React from 'react'
import { FormControl, TextField } from '@material-ui/core'

import styles from '../../styles.module.css'
const RestoreForm = ({
  isTempBlocked,
  setCode,
  setConfirmPassword,
  setNewPassword,
  userName,
}) => {
  return (
    <div>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          fullWidth
          id="outlined-basic-3"
          label="Correo"
          name="userName"
          type="text"
          variant="outlined"
          defaultValue={userName}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
      {isTempBlocked === false && (
        <>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              // error={!!error}
              fullWidth
              id="outlined-basic-7"
              label="Código"
              name="code"
              autoComplete="off"
              onChange={e => setCode(e.target.value.trim())}
              type="text"
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic-5"
              label="Nueva Contraseña"
              name="newPassword"
              autoComplete="off"
              onChange={e => setNewPassword(e.target.value.trim())}
              type="password"
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic-10"
              label="Confirme Nueva Contraseña"
              name="confirmPassword"
              autoComplete="off"
              onChange={e => setConfirmPassword(e.target.value.trim())}
              type="password"
              variant="outlined"
            />
          </FormControl>
        </>
      )}
    </div>
  )
}

export default RestoreForm
