import React from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'

import styles from './styles.module.css'

const VotesOptions = ({ vote, optionsDisabled, loading, lastVote }) => {
  return (
    <Grid container direction="row" justify="center" spacing={5}>
      <Grid item>
        <Button
          onClick={() => vote('NO')}
          className={lastVote && (lastVote.vote === 'NO') ? styles.lastVoteBtn : styles.btnVote}
          disabled={optionsDisabled || loading}
        >
          <ThumbDownIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => vote('MAYBE')}
          className={lastVote && (lastVote.vote === 'MAYBE') ? styles.lastVoteBtn : styles.btnVote}
          disabled={optionsDisabled || loading}
        >
          <HelpIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => vote('YES')}
          className={lastVote && (lastVote.vote === 'YES') ? styles.lastVoteBtn : styles.btnVote}
          disabled={optionsDisabled || loading}
        >
          <ThumbUpAltIcon />
        </Button>
      </Grid>
      {loading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : null}
    </Grid>
  )
}

export default VotesOptions