import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import axios from 'axios'

const APPLICATION_UPLOAD_URL = gql`
  query ApplicationUploadUrl($_id: ID!, $input: ApplicationUploadInput!) {
    applicationUploadUrl(_id: $_id, input: $input)
  }
`

const useApplicationUpload = () => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [file, setFile] = useState(null)
  const [applicationUploadURL, { data, error }] = useLazyQuery(
    APPLICATION_UPLOAD_URL,
  )

  useEffect(() => {
    const uploadFileToS3Effect = async () => {
      try {
        await axios
          .put(data.applicationUploadUrl, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: progressEvent =>
              setProgress(
                Math.round(progressEvent.loaded * 100) / progressEvent.total,
              ),
          })
          .catch(() => setLoading(false))
        setLoading(false)
        setFile(null)
      } catch (err) {
        setLoading(false)
      }
    }

    if (!data && !error && file) return
    if (error || (!data && !error)) return setLoading(false)

    if (data) return uploadFileToS3Effect()
  }, [data, error, file])

  const handleUploadFile = async ({ _id, file, type }) => {
    setFile(file)
    applicationUploadURL({
      variables: {
        _id,
        input: {
          type,
          filename: file.name,
        },
      },
    })
    setLoading(true)
  }

  return { handleUploadFile, loading, progress, setProgress }
}

export default useApplicationUpload
