import React from 'react'
import { Container, Tab, Tabs, withStyles } from '@material-ui/core'

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '80px',
      width: '100%',
      backgroundColor: '#161616',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#161616',
    fontWeight: 900,
    fontSize: '18px',
    '&:focus': {
      opacity: 1,
    },
    letterSpacing: '2px',
  },
}))(props => <Tab disableRipple {...props} />)

const roles = {
  SAURON: 'SAURON'
}

const UserTabs = ({ selectedTab, handleChange, role }) => {
  if(role !== roles.SAURON) return null
  return (
    <Container>
      <StyledTabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        style={{ marginBottom: 40 }}
      >
        <StyledTab label="Datos" />
        <StyledTab label="Vacantes" />
      </StyledTabs>
    </Container>
  )
}

export default UserTabs