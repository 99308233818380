import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'

import Pagination from '../../../../../../components/Pagination'
import { getPageItems } from '../../../../../../helpers/paginated'
import RankingCard from '../RankingCard'

import styles from './styles.module.css'

const RankingForm = ({ data }) => {
  const [elements, setElements] = useState({})

  const setPage = useCallback(
    (page = 1) => {
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(data, page, 6)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [data],
  )

  useEffect(() => {
    setPage()
  }, [data, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      <Grid container>
        <Grid item className={styles.cardContainer}>
          {elements.exists ? (
            elements.items.map((candidate, index) => (
              <div key={candidate._id}>
                <RankingCard candidate={candidate} position={index + 1} />
              </div>
            ))
          ) : (
            <Typography className={styles.emptyMessage}>
              <b>Aún no existe un ranking con los candidatos presentados</b>
            </Typography>
          )}
        </Grid>
      </Grid>
      {elements.exists && renderPagination()}
    </>
  )
}

RankingForm.defaultProps = {
  data: [],
}

export default RankingForm
