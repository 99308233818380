import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const ITEM_HEIGHT = 48

export default function LongMenu({ options, user, handleModal }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const enabledOrDisabledOption = name => {
    if (name === 'Deshabilitar' && user.disabledAt) return true
    else if (name === 'Habilitar' && !user.disabledAt) return true
    return false
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        {options.map(option => (
          <MenuItem
            key={option.name}
            onClick={() => handleModal(user)}
            disabled={enabledOrDisabledOption(option.name)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
