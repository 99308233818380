import React, { memo } from 'react'

import Comment from './Comment'

const CommentList = memo(({ posts, setPosts }) => {
  return (
    <div>
      {posts.map(post => (
        <div key={post.time}>
          <Comment
            post={post}
            posts={posts}
            setPosts={setPosts}
            text={post.message}
          />
        </div>
      ))}
    </div>
  )
})

export default CommentList
