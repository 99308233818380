import React from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'

import style from './style.module.css'

const headerColors = {
  VACANCY_COLOR: '#C5F4E0',
  RANKING_COLOR: '#C5F4E0',
  USERS_COLOR: '#ABD0EF',
  VIDEO_COLOR: '#ABD0EF',
  SPONTANEOUS_COLOR: '#F9EBB2',
  CANDIDATES_COLOR: '#F9EBB2',
  DEFAULT_COLOR: '#C5F4E0',
}

const PageHeader = ({ title, type, button = {} }) => {
  const displayButton = () => {
    if (!button.text) return

    return (
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={button.handleClick}
          className={style.btnHeader}
          disabled={button.disabled}
          style={{ float: 'right' }}>
          {button.text}
        </Button>
      </Grid>
    )
  }
  const getPageColor = () => {
    const {
      VACANCY_COLOR,
      RANKING_COLOR,
      USERS_COLOR,
      VIDEO_COLOR,
      SPONTANEOUS_COLOR,
      CANDIDATES_COLOR,
      DEFAULT_COLOR,
    } = headerColors

    switch (type) {
      case 'vacancy':
        return VACANCY_COLOR
      case 'users':
        return USERS_COLOR
      case 'spontaneous':
        return SPONTANEOUS_COLOR
      case 'candidates':
        return CANDIDATES_COLOR
      case 'video':
        return VIDEO_COLOR
      case 'ranking':
        return RANKING_COLOR
      default:
        return DEFAULT_COLOR
    }
  }
  return (
    <Grid
      container
      direction="column"
      justify="flex-end"
      style={{
        backgroundColor: getPageColor(),
        height: 260,
        marginBottom: 35,
      }}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h4"
              style={{ marginBottom: 30 }}
              className={style.headerText}>
              <b>{title ? title : 'Cargando Vacante...'}</b>
            </Typography>
          </Grid>
          {displayButton()}
        </Grid>
      </Container>
    </Grid>
  )
}

export default PageHeader
