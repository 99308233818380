import React from 'react'
import { Chip, Divider, FormControl, Grid, TextField } from '@material-ui/core'

import ProfileCV from './components/ProfileCV'

import styles from './styles.module.css'

const areasDictionary = {
  FINANCE: 'Finanzas',
  MARKETING: 'Marketing',
  COMMERCIAL: 'Comercial',
  ADMINISTRATION: 'Administración',
  LOGISTICS: 'Logística',
  IT: 'IT',
  CONSTRUCTION: 'Construcción',
  HOTEL: 'Hotelería y Restaurantes',
  HR: 'RRHH',
  OPERATIONS: 'Operaciones',
  OTHER: 'Otro',
}

const positionsDictionary = {
  GRADUATE: 'Recién titulado',
  ANALYST: 'Analista',
  ADMINISTRATIVE: 'Administrativo',
  BOSS: 'Jefe',
  MANAGER: 'Gerente',
}

const ApplicantInfo = ({ profile }) => {
  const {
    email,
    phone,
    expectedSalary,
    keywords,
    position,
    areas,
    resume,
  } = profile

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container direction="column">
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Correo electrónico"
              name="email"
              value={email}
              disabled
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Número de Teléfono"
              name="phone"
              value={phone}
              disabled
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Expectativa de Renta"
              name="expectedSalary"
              value={expectedSalary}
              disabled
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid>{resume ? <ProfileCV url={resume} /> : null}</Grid>
      </div>
      <Divider orientation="vertical" />
      <div style={{ width: '100%' }}>
        <Grid container direction="column">
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Palabras clave"
              name="keywords"
              value={keywords.join(',')}
              disabled
              multiline
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Nivel de cargo"
              name="position"
              value={positionsDictionary[position]}
              disabled
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid className={styles.areasSection}>
          {areas.map(area => (
            <Chip key={area} label={areasDictionary[area]} />
          ))}
        </Grid>
      </div>
    </>
  )
}

export default ApplicantInfo
