import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { cloneDeep } from 'lodash'

import Loading from '../../../../../components/Loading'
import Pagination from '../../../../../components/Pagination'
import { getPageItems } from '../../../../../helpers/paginated'
import SelectVacancies from '../Select'

import styles from './styles.module.css'

const VISIBLE_VACANCIES_BY_COMPANY = gql`
  query GetVisibleVacancies($_id: ID) {
    visibleVacancies(_id: $_id) {
      _id
      description
      location
      title
      status
    }
  }
`

const List = () => {
  const { companyId } = useParams()
  const [elements, setElements] = useState({})
  const [maxItems, setMaxItems] = useState(5)
  const history = useHistory()
  // const { loading, error, data } = useQuery(VISIBLE_VACANCIES_BY_COMPANY)
  const { loading, error, data } = useQuery(VISIBLE_VACANCIES_BY_COMPANY, {
    variables: { _id: companyId },
    fetchPolicy: 'network-only',
  })

  const handleClick = _id => history.push(`/vacancies/${_id}`)

  const setPage = useCallback(
    (page = 1) => {
      if (!data || !data.visibleVacancies) return
      const { visibleVacancies } = data
      const copyData = cloneDeep(visibleVacancies)
      const reversedItems = copyData.reverse()

      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(reversedItems, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [data, maxItems],
  )

  useEffect(() => {
    setPage()
  }, [data, maxItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  const handleSelectChange = newValue => {
    setMaxItems(newValue)
  }

  if (loading) return <Loading />
  if (error) return <p>Error :({JSON.stringify(error)})</p>

  return (
    <>
      {elements.exists && (
        <SelectVacancies value={maxItems} onChange={handleSelectChange} />
      )}
      {elements.exists &&
        elements.items.map((el, idx) => {
          return (
            <div key={idx.toString()}>
              <Accordion className={styles.list}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`${el._id}-content`}
                  id={`${el._id}-header`}>
                  <Typography>
                    <b>{el.title}</b> en {el.location}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>{el.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={styles.applyBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => handleClick(el._id)}>
                        Aplicar
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        })}
      {elements.exists && renderPagination()}
    </>
  )
}

export default List
