import React from 'react'
import ReactPlayer from 'react-player'
import { Divider, Link, Modal, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import styles from './styles.module.css'
const PLAYER_CONFIG = {
  file: {
    attributes: {
      controlslist: 'nodownload',
    },
  },
}
const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN

const VideoModal = ({ content, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper className={styles.paper}>
        <>
          <div className={styles.videoPlayerHeader}>
            <Typography variant="h5" style={{ marginBottom: 0 }}>
              <b>{content.fullName}</b> (video más reciente)
            </Typography>
            <Link className={styles.cancelBtn} onClick={handleClose}>
              <CloseIcon className={styles.cancelBtnIcon} />
            </Link>
          </div>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <div className={styles.videoPlayer}>
            <ReactPlayer
              url={`${CDN_URL}/${content.video}`}
              config={PLAYER_CONFIG}
              height="400px"
              width="100%"
              controls
            />
          </div>
        </>
      </Paper>
    </Modal>
  )
}

export default VideoModal
