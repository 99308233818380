import React from 'react'
import { Box, Grid } from '@material-ui/core'

import styles from './styles.module.css'

const ComercialContact = () => {
  return (
    <div className={styles.container}>
      <Box ml="30%" className={styles.wrapper}>
        <Grid item className={styles.grid} xs={12} sm={12} md={12} lg={12}>
          <h2 className={styles.titleContact}>Contacto Comercial</h2>
          <p className={styles.textContact}>
            Si tu empresa se quiere sumar al reclutamiento del
          </p>
          <p className={styles.textContact}>
            futuro, escríbenos a <strong>ventas@jobfitter.cl</strong>
          </p>
        </Grid>
      </Box>
    </div>
  )
}

export default ComercialContact
