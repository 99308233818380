import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Main from './Main'
import Vacancy from './Vacancy'

const Vacancies = ({ match }) => {
  return (
    <Switch>
      <Route component={Main} exact path={match.url} />
      <Route component={Vacancy} path={`${match.url}/:vacancyId`} />
    </Switch>
  )
}

export default Vacancies
