import React, { useContext } from 'react'
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'

import ProtectedComponent from '../../../../../components/ProtectedComponent'
import { Context } from '../../../../../context'
import { SAURON_ROLE } from '../../../../../userRoles'

import styles from './styles.module.css'

const UserForm = ({ companies, values, setValues, handleSave }) => {
  const { role } = useContext(Context)

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const renderCompanyItems = () => {
    return companies.map(company => (
      <MenuItem key={company._id} value={company._id}>
        {company.name}
      </MenuItem>
    ))
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Nombre"
              name="name"
              value={values?.name || ''}
              disabled={role !== SAURON_ROLE}
              variant="outlined"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Teléfono"
              name="phone"
              value={values?.phone || ''}
              disabled={role !== SAURON_ROLE}
              variant="outlined"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Correo electrónico"
              name="email"
              value={values?.email || ''}
              disabled
              variant="outlined"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Contraseña"
              name="password"
              value="**********"
              disabled
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={styles.inputGroup}>
            {role === SAURON_ROLE ? (
              <>
                <InputLabel id="user-company-select">Empresa</InputLabel>
                <Select
                  labelId="user-company-select"
                  value={values?.company || ''}
                  disabled={role !== SAURON_ROLE}
                  label="Empresa"
                  name="company"
                  onChange={handleChange}>
                  {renderCompanyItems()}
                </Select>
              </>
            ) : (
              <TextField
                label="Empresa"
                value={values?.company?.name || ''}
                disabled={role !== SAURON_ROLE}
                variant="outlined"
              />
            )}
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Cargo"
              name="position"
              value={values?.position || ''}
              disabled={role !== SAURON_ROLE}
              variant="outlined"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.inputGroup}>
            <TextField
              label="Área"
              name="area"
              value="IT"
              disabled={role !== SAURON_ROLE}
              variant="outlined"
            />
          </FormControl>
          <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
            <Button className={styles.saveBtn} onClick={handleSave}>
              Guardar cambios
            </Button>
          </ProtectedComponent>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserForm
