import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Grid, Typography } from '@material-ui/core'
import { get } from 'lodash'

import Information from './components/Information'
import Questions from './components/Questions'

import styles from './styles.module.css'

const COMPANIES = gql`
  query GetCompanies {
    companies {
      _id
      name
    }
  }
`

const VacancyForm = ({ values, setValues, users, handleEdit, editButtonDisabled }) => {
  const [companies, setCompanies] = useState([])
  const { data } = useQuery(COMPANIES)

  useEffect(() => {
    const companies = get(data, 'companies', [])
    setCompanies(companies)
  }, [data])

  return (
    <form>
      <Grid container direction="row">
        <Grid item sm={6} className={styles.formContainer}>
          <Typography className={styles.sectionTitle}>
            <b>Información Vacante</b>
          </Typography>
          <Information
            setValues={setValues}
            values={values}
            companies={companies}
            users={users}
            handleEdit={handleEdit}
            editButtonDisabled={editButtonDisabled}
          />
        </Grid>
        <Grid item sm={6} className={styles.formContainer}>
          <Typography className={styles.sectionTitle}>
            <b>Preguntas de la Vacante</b>
          </Typography>
          <Questions
            setValues={setValues}
            values={values}
            disabled={!!handleEdit}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default VacancyForm
