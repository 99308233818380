import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { get } from 'lodash'

import styles from './styles.module.css'

const USER_VACANCIES = gql`
  query UserVacancies($_id: ID!) {
    userVacancies(_id: $_id) {
      _id
      title
      location
    }
  }
`

const UserVacancies = () => {
  const { push } = useHistory()
  const { userId } = useParams()
  const [vacancies, setVacancies] = useState([])
  const { loading, data } = useQuery(USER_VACANCIES, {
    variables: { _id: userId }
  })

  useEffect(() => {
    const vacancies = get(data, 'userVacancies', [])
    setVacancies(vacancies)
  }, [data])

  if (loading) return (
    <Container>
      <CircularProgress />
    </Container>
  )
  return (
    <Container>
      <Grid
        container
        direction="column"
        spacing={3}
        className={styles.mainContentList}
      >
        {vacancies.length !== 0 ? vacancies.map(el => (
          <Grid item xs key={el._id}>
            <Paper variant="elevation" square className={styles.paperList}>
              <Grid container alignContent="center" className={styles.listItem}>
                <Grid className={styles.infoVacancy} onClick={() => push(`/admin/vacancies/${el._id}`)}>
                  <Typography className={styles.vacTitle}>{el.title}</Typography>
                  <Typography className={styles.location}>en {el.location}</Typography>
                </Grid>
                <Grid className={styles.options}>
                  <Button
                    className={styles.btn}
                    onClick={() => push(`/admin/vacancies/${el._id}`)}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )) : (
            <Typography>
              <b>No se encontraron vacantes</b>
            </Typography>
          )}
      </Grid>
    </Container>
  )
}

export default UserVacancies