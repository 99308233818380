import { gql } from '@apollo/client'

export const APPLICATIONS = gql`
  query Applications($input: ApplicationByRoleInput!) {
    applications(input: $input) {
      _id
      applierName
      applierPhone
      applierExpectedSalary
      applierEmail
      status
      resume
      video
      createdAt
      notes {
        user
        message
        time
      }
      interviews {
        user
        message
        time
      }
      videoResults {
        vote
        userid
      }
      previousApplications {
        status
        vacancy {
          _id
          title
        }
      }
      answers {
        question
        answer
      }
    }
  }
`

export const VACANCY = gql`
  query Vacancy($_id: ID!) {
    vacancy(_id: $_id) {
      _id
      title
      area
      seniorityLevel
      status
      location
      maxIncome
      description
      createdAt
      updatedAt
      applicationsToVote {
        _id
        applierName
        video
        status
        videoResults {
          vote
          userid
        }
      }
      questions {
        text
        type
        options
      }
      company {
        _id
      }
      users {
        _id
      }
    }
  }
`
