import React from 'react'
import { Button } from '@material-ui/core'

import styles from './styles.module.css'

const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN

const ButtonCvModal = ({ url }) => {
  return (
    <div>
        <a
          className={styles.downloadLink}
          href={`${CDN_URL}/${url}`}
          target="_blank"
          download>
            <Button variant="contained" className={styles.cvBtn}>
              Descarga de CV
            </Button>
        </a>
    </div>
  )
}

export default ButtonCvModal
