import React, { forwardRef, useState } from 'react'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import OptionsAreas from './components/OptionsAreas'

import styles from './styles.module.css'

const positions = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]

const SpontaneousForm = forwardRef(({ values, setValues }, ref) => {
  const [cvButtonLabel, setCvButtonLabel] = useState('Subir CV')

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleKeywordsChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value.split(',') })
  }

  const handleSetAreas = areas => {
    setValues({ ...values, areas })
  }

  const handleInputFileChange = event => {
    setCvButtonLabel(event.target.files[0].name)
  }

  return (
    <Grid container justify="center">
      <Grid item xs={11} sm={8} md={4}>
        <Paper style={{ padding: 30 }}>
          <Typography style={{ marginBottom: 20 }}>
            <b>Información</b>
          </Typography>
          <Divider className={styles.divider} />
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              id="outlined-basic"
              label="Nombre"
              variant="outlined"
              name="name"
              helperText="Escribe el nombre por el que nos podemos referir a ti"
              fullWidth
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              id="outlined-basic"
              label="Teléfono"
              variant="outlined"
              name="phone"
              placeholder="56999999999"
              fullWidth
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon className={styles.plusIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              id="outlined-basic"
              label="Correo electrónico"
              variant="outlined"
              name="email"
              fullWidth
              onChange={handleChange}
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              id="outlined-basic"
              label="Expectativa de renta"
              variant="outlined"
              name="expectedSalary"
              helperText="En la moneda del país de la vacante. Sin puntos (.) ni comas (,)"
              fullWidth
              type="number"
              placeholder="Ej: 1200000"
              onChange={handleChange}
            />
          </FormControl>
          <input
            className={styles.fileInput}
            id="cv-button"
            type="file"
            onChange={handleInputFileChange}
            ref={ref}
            accept=".doc,.docx, application/msword, application/pdf, image/*"
          />
          <label htmlFor="cv-button">
            <Button
              variant="contained"
              component="span"
              className={styles.cvButton}>
              {cvButtonLabel}
            </Button>
          </label>
          <OptionsAreas setValues={handleSetAreas} />
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              id="outlined-basic"
              label="De acuerdo a tu área de interés, agrega palabras clave"
              variant="outlined"
              name="keywords"
              helperText="Separa tus palabras con una coma (,) "
              fullWidth
              onChange={handleKeywordsChange}
            />
          </FormControl>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={styles.formControl}>
            <InputLabel id="role-select">
              ¿En que nivel de cargo estás actualmente?
            </InputLabel>
            <Select
              labelId="role-select"
              label="¿En que nivel de cargo estás actualmente?"
              name="position"
              onChange={handleChange}>
              {positions.map(position => (
                <MenuItem key={position.value} value={position.value}>
                  {position.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      </Grid>
    </Grid>
  )
})

export default SpontaneousForm
