import React, { useContext } from 'react'

import { Context } from '../../context'

const ProtectedComponent = ({ children, allowedRoles }) => {
  const { role } = useContext(Context)

  return allowedRoles.indexOf(role) !== -1
    ? children
    : <></>
}

export default ProtectedComponent
