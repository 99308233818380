import React from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}))

import MultipleOptions from './components/MultipleOptions'

const Questions = ({ setValues, values, disabled }) => {
  const remove = _id => {
    const currentQuestions = [...values.questions]
    const index = currentQuestions.findIndex(el => el._id === _id)
    currentQuestions.splice(index, 1)
    setValues({ ...values, questions: currentQuestions })
  }

  const addQuestion = () => {
    const currentQuestions = [
      ...values.questions,
      { _id: new Date().valueOf(), type: 'OPEN' },
    ]

    setValues({ ...values, questions: currentQuestions })
  }
  const onChange = (event, _id) => {
    const currentQuestions = [...values.questions]
    const index = currentQuestions.findIndex(question => question._id === _id)
    currentQuestions[index][event.target.name] = event.target.value
    setValues({
      ...values,
      questions: currentQuestions,
    })
  }
  const onChangeMultipleOptions = (options, _id) => {
    const currentQuestions = [...values.questions]
    const index = currentQuestions.findIndex(question => question._id === _id)
    currentQuestions[index].options = options
    setValues({
      ...values,
      questions: currentQuestions,
    })
  }
  const classes = useStyles()
  return (
    <Paper style={{ padding: 30 }}>
      {values.questions.map(el => {
        return (
          <div key={el._id}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Tipo de pregunta
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={el.type || 'OPEN'}
                name="type"
                fullWidth
                disabled={disabled}
                onChange={event => onChange(event, el._id)}>
                <MenuItem value={'OPEN'}>Abierta</MenuItem>
                <MenuItem value={'MULTIPLE_CHOICE'}>Opción múltiple</MenuItem>
              </Select>
            </FormControl>

            {el.type !== '' && (
              <FormControl className={classes.formControl}>
                <TextField
                  id="outlined-basic"
                  label="Pregunta"
                  variant="outlined"
                  name="text"
                  defaultValue={el.text}
                  fullWidth
                  multiline
                  disabled={disabled}
                  onChange={event => onChange(event, el._id)}
                />
              </FormControl>
            )}
            {el.type === 'MULTIPLE_CHOICE' && (
              <MultipleOptions
                onChange={values => onChangeMultipleOptions(values, el._id)}
                defaultOptions={el.options}
                disabled={disabled}
              />
              // <FormControl component="fieldset" className={classes.formControl}>
              //   <FormLabel component="legend">Opciones</FormLabel>
              //   <RadioGroup
              //     aria-label="gender"
              //     name="option"
              //     value={
              //       values.questions[
              //         values.questions.findIndex(q => q._id === el._id)
              //       ].option ?? 1
              //     }
              //     onChange={event => onChange(event, el._id)}>
              //     <FormControlLabel
              //       value="1"
              //       control={<Radio disabled />}
              //       label="Opción 1"
              //     />
              //     <FormControlLabel
              //       value="2"
              //       control={<Radio disabled />}
              //       label="Opción 2"
              //     />
              //     <FormControlLabel
              //       value="3"
              //       control={<Radio disabled />}
              //       label="Opción 3"
              //     />
              //   </RadioGroup>
              // </FormControl>
            )}

            <Button
              disabled={disabled}
              onClick={() => remove(el._id)}
              style={{
                float: 'right',
                backgroundColor: '#d9c7e0',
                marginBottom: 15,
              }}>
              Eliminar Pregunta
            </Button>
          </div>
        )
      })}
      <Button
        onClick={addQuestion}
        disabled={disabled}
        fullWidth
        style={{ backgroundColor: '#d1eaff' }}>
        Agregar Pregunta
      </Button>
    </Paper>
  )
}

export default Questions
