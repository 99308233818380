import React from 'react'
import { Button } from '@material-ui/core'

import styles from './styles.module.css'

const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN

const ButtonCvModal = ({ cvURL }) => {
  return (
    <div>
      <Button variant="contained" className={styles.cvBtn} disabled={!cvURL}>
        <a
          className={styles.downloadLink}
          href={`${CDN_URL}/${cvURL}`}
          target="_blank"
          download>
          Descarga de CV
        </a>
      </Button>
    </div>
  )
}

export default ButtonCvModal
