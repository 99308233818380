import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Container } from '@material-ui/core'
import { get } from 'lodash'

import Loading from '../../../components/Loading'
import PageHeader from '../../../components/PageHeader'
import ProtectedPage from '../../../components/ProtectedPage'
import { CONSULTANT_ROLE, SAURON_ROLE } from '../../../userRoles'

import ApplyModal from './components/ApplyModal'
import Options from './components/Options'
import ProfilesList from './components/ProfilesList'

// import styles from './styles.module.css'

const VACANCIES = gql`
  query GetVacancies {
    visibleVacancies {
      _id
      title
    }
  }
`

const SPONTANEOUS = gql`
  query Spontaneous($input: SpontaneousInput!) {
    spontaneous(input: $input) {
      _id
      name
      email
      phone
      expectedSalary
      resume
      areas
      keywords
      position
    }
  }
`

const DELETE_SPONTANEOUS = gql`
  mutation DeleteSpontaneous($_id: ID!) {
    deleteSpontaneous(_id: $_id) {
      _id
    }
  }
`

const Profiles = () => {
  const [getProfiles, { data, loading, refetch }] = useLazyQuery(SPONTANEOUS, {
    fetchPolicy: 'network-only',
  })

  const { loading: vacancyLoading, data: vacancyData } = useQuery(VACANCIES)
  const [deleteSpontaneous] = useMutation(DELETE_SPONTANEOUS)
  const [profiles, setProfiles] = useState([])
  const [maxItems, setMaxItems] = useState(5)
  const [vacancies, setVacancies] = useState([])
  const [spontaneousSelected, setSpontaneous] = useState({})
  const [applyModalOpen, setApplyModal] = useState(false)
  const [filters, setFilters] = useState({
    area: 'ALL',
    position: 'ALL',
    name: '',
    keywords: [],
    expectedSalary: -1,
  })

  useEffect(() => {
    const profiles = get(data, 'spontaneous', [])
    const vacancies = get(vacancyData, 'visibleVacancies', [])
    setProfiles(profiles)
    setVacancies(vacancies)
  }, [data, vacancyData])

  const handleSearch = () => {
    const input = { ...filters }

    if (input.area === 'ALL') delete input.area
    if (input.position === 'ALL') delete input.position
    if (input.area === '') delete input.area
    if (!input.keywords.length) delete input.keywords
    if (input.expectedSalary === -1 || isNaN(input.expectedSalary))
      delete input.expectedSalary
    getProfiles({
      variables: {
        input,
      },
    })
  }

  const handleDelete = async () => {
    try {
      await deleteSpontaneous({
        variables: {
          _id: spontaneousSelected._id,
        },
      })
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectSpontaenous = spontaneous => {
    setSpontaneous(spontaneous)
    setApplyModal(true)
  }

  return (
    <ProtectedPage allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
      <PageHeader title="Candidatos Espontáneos" type="spontaneous" />
      <Container>
        <Options
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearch}
          loading={loading}
          maxItems={maxItems}
          setMaxItems={setMaxItems}
        />
        {loading ? (
          <Loading />
        ) : (
          <ProfilesList
            profiles={profiles || vacancyLoading}
            handleToApply={handleSelectSpontaenous}
            maxItems={maxItems}
          />
        )}
      </Container>
      <ApplyModal
        open={applyModalOpen}
        handleClose={() => setApplyModal(false)}
        vacancies={vacancies}
        spontaneousToApply={spontaneousSelected}
        callback={handleDelete}
      />
    </ProtectedPage>
  )
}

export default Profiles
