import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab'
import { Auth } from 'aws-amplify'

import NewPasswordForm from './components/NewPasswordForm'
import RestorePassword from './components/RestorePassword'

import styles from './styles.module.css'

const LoginForm = ({
  setUser,
  setUserName,
  setCompletePasswordForm,
  setActiveResetPassword,
  setShowPasswordResetMessageCompletion,
  showPasswordResetMessageCompletion,
}) => {
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const history = useHistory()

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleActiveResetPasswordClick = () => {
    setActiveResetPassword(true)
  }

  const handleClick = () => {
    setErrorMessage('')
    setLoading(true)
    const { password } = values
    let { username } = values
    if (!username) {
      setErrorMessage('Por favor, debe introducir el correo.')
      setLoading(false)
      return
    }
    if (!password) {
      setErrorMessage('Por favor, debe introducir la contraseña.')
      setLoading(false)
      return
    }
    username = username.trim()
    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserName(values.username.trim())
          setUser(user)
          setLoading(false)
          setCompletePasswordForm(true)
          return
        }
        history.push('/admin')
      })
      .catch(err => {
        if (err.code === 'NotAuthorizedException')
          setErrorMessage(
            'Hubo un error, el usuario y/o contraseña no son correctos. Inténtelo de nuevo',
          )
        else if (err.code === 'UserNotFoundException')
          setErrorMessage(
            'Hubo un error, usuario no existe. Inténtelo de nuevo',
          )
        else setErrorMessage('Hubo un error. Inténtelo de nuevo')
        setLoading(false)
      })
  }

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Bienvenido
      </Typography>
      <Collapse in={showPasswordResetMessageCompletion}>
        <Alert
          severity="success"
          variant="outlined"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowPasswordResetMessageCompletion(false)
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {`Se ha realizado el cambio de contraseña exitosamente, vuelva a iniciar sesión.`}
        </Alert>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      </Collapse>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic-1"
          label="Correo"
          variant="outlined"
          name="username"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic-2"
          label="Contraseña"
          variant="outlined"
          type="password"
          name="password"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <Typography style={{ color: 'red', marginBottom: 20 }}>
        {errorMessage}
      </Typography>
      <Link
        to="#"
        color="primary"
        variant="inherit"
        onClick={handleActiveResetPasswordClick}>
        Olvidé mi contraseña
      </Link>
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
      <Button
        variant="contained"
        color="primary"
        className={styles.loginBtn}
        onClick={handleClick}
        disabled={loading}>
        {loading ? 'Cargando' : 'Iniciar sesión'}
      </Button>
    </>
  )
}

const LoginModal = ({ open, setOpen }) => {
  const [user, setUser] = useState({})
  const [userName, setUserName] = useState('')
  const [completePasswordForm, setCompletePasswordForm] = useState(false)
  const [activeResetPassword, setActiveResetPassword] = useState(false)
  const [
    showPasswordResetMessageCompletion,
    setShowPasswordResetMessageCompletion,
  ] = useState(false)

  const handleClose = () => {
    setUser({})
    setCompletePasswordForm(false)
    setActiveResetPassword(false)
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper className={styles.paper}>
        {!completePasswordForm ? (
          <>
            {!activeResetPassword ? (
              <>
                <LoginForm
                  setUser={setUser}
                  setUserName={setUserName}
                  setCompletePasswordForm={setCompletePasswordForm}
                  setActiveResetPassword={setActiveResetPassword}
                  showPasswordResetMessageCompletion={
                    showPasswordResetMessageCompletion
                  }
                  setShowPasswordResetMessageCompletion={
                    setShowPasswordResetMessageCompletion
                  }
                />
              </>
            ) : (
              <>
                <RestorePassword
                  setActiveResetPassword={setActiveResetPassword}
                  setShowPasswordResetMessageCompletion={
                    setShowPasswordResetMessageCompletion
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            <NewPasswordForm user={user} userName={userName} />
          </>
        )}
      </Paper>
    </Modal>
  )
}

export default LoginModal
