import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import ProtectedComponent from '../../../../../../../components/ProtectedComponent'
import { CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE } from '../../../../../../../userRoles'

const ITEM_HEIGHT = 48

const VacancyMenu = ({ className, onSelectDelete, onSelectApply }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectDelete = () => {
    handleClose()
    onSelectDelete()
  }

  const handleSelectApply = () => {
    handleClose()
    onSelectApply()
  }

  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleSelectApply}>
          Agregar candidato
        </MenuItem>
        <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
          <MenuItem onClick={handleSelectDelete}>
            Eliminar Vacante
          </MenuItem>
        </ProtectedComponent>
      </Menu>
    </div>
  )
}

export default VacancyMenu