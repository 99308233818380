import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import MessageModal from '../../../../../../components/MessageModal'
import ProtectedComponent from '../../../../../../components/ProtectedComponent'
import { SAURON_ROLE } from '../../../../../../userRoles'

import ApplicantsList from './components/ApplicantsList'
import Options from './components/Options'
import useEmail from './hooks/useEmails'
import { statusOptions } from './data'

import styles from './styles.module.css'

const UPDATE_APPLICATIONS = gql`
  mutation UpdateApplications($ids: [ID!], $input: UpdateApplicationsInput!) {
    updateApplications(ids: $ids, input: $input) {
      _id
    }
  }
`

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($_id: ID!, $input: UpdateApplicationInput!) {
    updateApplication(_id: $_id, input: $input) {
      _id
    }
  }
`

const DELETE_APPLICATION = gql`
  mutation DeleteApplication($_id: ID!) {
    deleteApplication(_id: $_id) {
      _id
    }
  }
`

const status = {
  VIDEO_REQUESTED: 'VIDEO_REQUESTED',
  TO_UNLINK: 'TO_UNLINK',
  UNLINKED: 'UNLINKED',
}

const VideoVotingTab = ({
  applications,
  handleReload,
  questions,
  vacancyStatus,
  handleAppReload,
}) => {
  const [maxItems, setMaxItems] = useState(5)
  const [filteredApplications, setFilteredApplications] = useState([])
  const [applicantsSelected, setApplicants] = useState([])
  const [applicantToDelete, setApplicantToDelete] = useState({})
  const [success, setStateSuccess] = useState('')
  const [error, setStateError] = useState('')
  const [isAllAplicantsSelcted, setAllAplicants] = useState(false)
  const [isModalOpen, setStateModal] = useState(false)
  const [isDeleteModalOpen, setStateDeleteModal] = useState(false)
  const [updateApplications] = useMutation(UPDATE_APPLICATIONS)
  const [deleteApplication] = useMutation(DELETE_APPLICATION)
  const [updateApplication] = useMutation(UPDATE_APPLICATION)
  const { handleNotifyAccepted, handleNotifyRejected } = useEmail()
  const [filters, setFilters] = useState({
    type: 'ALL',
    text: '',
    applierExpectedSalary: 'ALL',
  })

  useEffect(() => {
    const filtered = applications.filter(el => {
      const { type, text, applierExpectedSalary } = filters
      const textMatch = el.applierName
        .toLowerCase()
        .includes(text.toLowerCase())
      const typeMatch = type === 'ALL' || type === el.status
      const expectedSalaryMath =
        applierExpectedSalary === 'ALL' ||
        parseInt(el.applierExpectedSalary) <= applierExpectedSalary
      return textMatch && typeMatch && expectedSalaryMath
    })

    setFilteredApplications(filtered)
  }, [filters, applications])

  const handleStop = event => {
    event.stopPropagation()
  }
  const handleSelectApplicant = applicantId => {
    const applicantsToUnlink = [...applicantsSelected]

    if (applicantsSelected.indexOf(applicantId) !== -1)
      setApplicants(applicantsToUnlink.filter(id => applicantId !== id))
    else {
      applicantsToUnlink.push(applicantId)
      setApplicants(applicantsToUnlink)
    }
  }
  const handleSelectAll = () => {
    if (!isAllAplicantsSelcted) {
      const applicantsToUnlink = filteredApplications.filter(
        application => application.status === status.TO_UNLINK,
      )
      setAllAplicants(true)
      setApplicants(applicantsToUnlink.map(applicant => applicant._id))
    } else {
      setAllAplicants(false)
      setApplicants([])
    }
  }
  const handleSendNotification = async (id, nextStatus) => {
    let sendNotification = () => {}

    if (nextStatus === status.VIDEO_REQUESTED)
      sendNotification = handleNotifyAccepted

    if (nextStatus === status.UNLINKED) sendNotification = handleNotifyRejected

    await sendNotification(id)

    setStateSuccess(
      'El postulante acaba de ser notificado con un correo electrónico',
    )
  }
  const handleConfirmAll = async () => {
    try {
      await updateApplications({
        variables: {
          ids: applicantsSelected,
          input: { status: status.UNLINKED },
        },
      })
      setStateModal(false)
      handleReload()
      handleAppReload()
      setStateSuccess('Los postulantes han sido desvinculados correctamente')
      setAllAplicants(false)

      applicantsSelected.forEach(async application => {
        await handleSendNotification(application, status.UNLINKED)
      })
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    }
  }
  const handleUpdateApplication = async (id, data) => {
    try {
      await updateApplication({ variables: { _id: id, input: { ...data } } })
      handleReload()
      handleAppReload()
      setStateSuccess('La acción se ha realizado correctamente.')

      if (
        data.status === status.VIDEO_REQUESTED ||
        data.status === status.UNLINKED
      )
        await handleSendNotification(id, data.status)
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    }
  }

  const handleApplicationToDelete = application => {
    setApplicantToDelete(application)
    setStateDeleteModal(true)
  }

  const handleDeleteApplication = async () => {
    try {
      await deleteApplication({
        variables: {
          _id: applicantToDelete._id,
        },
      })
      setStateDeleteModal(false)
      handleReload()
      handleAppReload()
      setStateSuccess('Se ha eliminado la postulación correctamente')
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    }
  }

  return (
    <>
      <Options
        filters={filters}
        setFilters={setFilters}
        statusOptions={statusOptions}
        handleUnlink={() => setStateModal(true)}
        unlinkButtonDisabled={applicantsSelected.length === 0}
        isUnlinkBtnVisible={filters.type === 'TO_UNLINK'}
        maxItems={maxItems}
        setMaxItems={setMaxItems}
      />
      <ApplicantsList
        maxItems={maxItems}
        applicantions={filteredApplications}
        handleStop={handleStop}
        statusOptions={statusOptions}
        handleSelectApplicant={handleSelectApplicant}
        handleSelectAllAplicants={handleSelectAll}
        applicantsSelected={applicantsSelected}
        handleUpdateApplication={handleUpdateApplication}
        isUnlinkBtnVisible={filters.type === 'TO_UNLINK'}
        currentFilterType={filters.type}
        questions={questions}
        vacancyStatus={vacancyStatus}
        handleToDelete={handleApplicationToDelete}
      />
      <MessageModal
        open={isModalOpen}
        okText="Desvincular"
        handleClose={() => setStateModal(false)}
        handleConfirm={handleConfirmAll}
        title={`Alerta: Desvincular ${
          isAllAplicantsSelcted
            ? filteredApplications.find(
                application => application._id === applicantsSelected[0],
              ).applierName
            : 'Seleccionados'
        }`}
        message={
          <div className={styles.statusChange}>
            <Typography className={styles.statusText}>
              Estado actual: <b>Por desvincular</b>
            </Typography>
            <Typography className={styles.statusText}>
              Nuevo estado: <b>Desvinculado</b>
            </Typography>
          </div>
        }
      />
      <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
        <MessageModal
          open={isDeleteModalOpen}
          okText="Sí"
          cancelText="No"
          handleClose={() => setStateDeleteModal(false)}
          handleConfirm={handleDeleteApplication}
          title="Alerta: Borrar postulante"
          message={
            <Typography>
              ¿Está seguro que quiere <b>eliminar de manera definitiva</b> a
              este postulante? Esta acción no podrá revertirse.
            </Typography>
          }
        />
      </ProtectedComponent>
      <Snackbar
        open={!!success}
        onClose={() => setStateSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success">{success}</Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        onClose={() => setStateSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  )
}

VideoVotingTab.defaultProps = {
  applications: [],
  questions: [],
  vacancyStatus: '',
}

export default VideoVotingTab
