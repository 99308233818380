import React, { useState } from 'react'
import { MenuItem, Select, Typography } from '@material-ui/core'

import MessageModal from '../../../../../../../../../../components/MessageModal'

import styles from './styles.module.css'

const statusDictionary = {
  'PENDING_CONTACT': 'Por Contactar',
  'VIDEO_REQUESTED': 'Video Solicitado',
  'VIDEO_SENT': 'Video Recibido',
  'APPLICANT_PRESENTED': 'Candidato Presentado',
  'HIRED': 'Contratado',
  'TO_UNLINK': 'Por Desvincular',
  'UNLINKED': 'Desvinculado',
}

const ApplicantStatusSelect = ({ handleStop, state, statusOptions, handleStatusChange, applicantName, vacancyStatus }) => {
  const [stateVacancy, setStateVacancy] = useState(state)
  const [backupVacancy, setStateBackupVacancy] = useState(state)
  const [modalConfirmOpen, setModalConfirm] = useState(false)

  const handleChange = event => {
    setStateBackupVacancy(event.target.value)
    setModalConfirm(true)
    event.stopPropagation()
  }

  const handleConfirm = () => {
    handleStatusChange(backupVacancy)
    setStateVacancy(backupVacancy)
    setModalConfirm(false)
  }

  const emailWarning = state => {
    if(state === 'Video Solicitado')
      return 'Se le enviará un mail al candidato.'

    if(state === 'Desvinculado')
      return 'Se le enviará un mail de desvinculación.'
  }


  return (
    <>
      <Select
        className={styles.statusSelect}
        onClick={handleStop}
        variant="outlined"
        name="stateVacancy"
        value={stateVacancy}
        disabled={vacancyStatus === 'HIDDEN'}
        onChange={handleChange}>
        {statusOptions.find(opt => opt.value === stateVacancy).nextStatus
        .map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      <MessageModal
        open={modalConfirmOpen}
        title={`Nuevo estado para ${applicantName}`}
        message={
          <>
            <span>¿Estás seguro que quieres cambiar el estado de {applicantName}? { emailWarning(statusDictionary[backupVacancy]) }</span>
            <div className={styles.statusChange}>
              <Typography className={styles.statusText}>Estado actual: <b>{statusDictionary[stateVacancy]}</b></Typography>
              <Typography className={styles.statusText}>Nuevo estado: <b>{statusDictionary[backupVacancy]}</b></Typography>
            </div>
          </>
        }
        handleClose={() => setModalConfirm(false)}
        handleConfirm={handleConfirm}
      />
    </>
  )
}

export default ApplicantStatusSelect
