import React from 'react'
import {
  FormControl,
  Grid,
  // InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  // TextField,
} from '@material-ui/core'

// import SearchIcon from '@material-ui/icons/Search'
import styles from './styles.module.css'

// const areas = [
//   { label: 'Ver todo', value: 'ALL' },
//   { label: 'Finanzas', value: 'FINANCE' },
//   { label: 'Marketing', value: 'MARKETING' },
//   { label: 'Comercial', value: 'COMMERCIAL' },
//   { label: 'Administración', value: 'ADMINISTRATION' },
//   { label: 'Logística', value: 'LOGISTICS' },
//   { label: 'IT', value: 'IT' },
//   { label: 'Construcción', value: 'CONSTRUCTION' },
//   { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
//   { label: 'RRHH', value: 'HR' },
//   { label: 'Operaciones', value: 'OPERATIONS' },
//   { label: 'Otro', value: 'OTHER' },
// ]

// const seniorityLevels = [
//   { label: 'Ver todo', value: 'ALL' },
//   { value: 'GRADUATE', label: 'Recién titulado' },
//   { value: 'ANALYST', label: 'Analista' },
//   { value: 'ADMINISTRATIVE', label: 'Administrativo' },
//   { value: 'BOSS', label: 'Jefe' },
//   { value: 'MANAGER', label: 'Gerente' },
// ]

const SelectVacancies = ({
  //  filters,
  //  setFilters,
  setMaxItems,
  maxItems,
}) => {
  // const renderSearch = () => (
  //   <Grid item xs={11} sm={4} justify="center" alignItems="center">
  //     <FormControl component="fieldset" className={styles.search}>
  //       <TextField
  //         id="outlined-basic"
  //         label="Búsqueda"
  //         variant="outlined"
  //         name="search"
  //         fullWidth
  //         onChange={event =>
  //           setFilters({
  //             ...filters,
  //             [event.target.name]: event.target.value,
  //           })
  //         }
  //         InputProps={{
  //           startAdornment: (
  //             <InputAdornment position="start">
  //               <SearchIcon />
  //             </InputAdornment>
  //           ),
  //         }}
  //       />
  //     </FormControl>
  //   </Grid>
  // )

  const renderItems = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="items-select-label">Cantidad de vacantes</InputLabel>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={maxItems}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={event => setMaxItems(event.target.value)}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  // const renderArea = () => (
  //   <Grid item xs={5} sm={2}>
  //     <FormControl style={{ width: '100%' }}>
  //       <InputLabel id="role-select-area-label">Área</InputLabel>
  //       <Select
  //         labelId="role-select-area-label"
  //         id="area-select"
  //         name="area"
  //         value={filters.area}
  //         defaultValue={'ALL'}
  //         fullWidth
  //         onChange={event =>
  //           setFilters({
  //             ...filters,
  //             [event.target.name]: event.target.value,
  //           })
  //         }>
  //         <MenuItem value="IT">Ver todas</MenuItem>
  //         {areas.map(area => (
  //           <MenuItem key={area.value} value={area.value}>
  //             {area.label}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   </Grid>
  // )

  // const renderSenioritylevel = () => (
  //   <Grid item xs={5} sm={2}>
  //     <FormControl style={{ width: '100%' }}>
  //       <InputLabel id="role-seniorityLevel-label">Cargo</InputLabel>
  //       <Select
  //         labelId="role-seniorityLevel-label"
  //         id="seniorityLevel-select"
  //         value={filters.seniorityLevel}
  //         defaultValue={'ALL'}
  //         name="seniorityLevel"
  //         fullWidth
  //         onChange={event =>
  //           setFilters({
  //             ...filters,
  //             [event.target.name]: event.target.value,
  //           })
  //         }>
  //         <MenuItem value="BOSS">Ver todas</MenuItem>
  //         {seniorityLevels.map(area => (
  //           <MenuItem key={area.value} value={area.value}>
  //             {area.label}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   </Grid>
  // )

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.searchOptions}>
        {/* {renderSearch()} */}
        {renderItems()}
        {/* {renderArea()}
        {renderSenioritylevel()} */}
      </Grid>
    </>
  )
}

export default SelectVacancies
