import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { get } from 'lodash'

import Loading from '../../../../components/Loading'
import PageHeader from '../../../../components/PageHeader'
import ProtectedComponent from '../../../../components/ProtectedComponent'
import { Context } from '../../../../context'
import { CONSULTANT_ROLE, SAURON_ROLE } from '../../../../userRoles'

import ApplicantsTab from './components/ApplicantsTab'
import VacancyInfoTab from './components/VacancyInfoTab'
import VideoVotingTab from './components/VideoVotingTab'
import { APPLICATIONS, VACANCY } from './queries'

import styles from './styles.module.css'

const statusEnum = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '80px',
      width: '100%',
      backgroundColor: '#161616',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#161616',
    fontWeight: 900,
    fontSize: '18px',
    '&:focus': {
      opacity: 1,
    },
    letterSpacing: '2px',
  },
}))(props => <Tab disableRipple {...props} />)

const Vacancy = () => {
  const [vacancy, setVacancy] = useState(null)
  const [applications, setApplications] = useState([])
  const [pageTitle, setPageTitle] = useState('Mis vacantes')
  const [selectedTab, setSelectedTab] = useState(0)

  const history = useHistory()
  const { vacancyId } = useParams()

  const { VISIBLE } = statusEnum

  const { loading, error, data, refetch } = useQuery(VACANCY, {
    variables: { _id: vacancyId },
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingApps,
    error: errorApps,
    data: dataApps,
    refetch: refetchApps,
  } = useQuery(APPLICATIONS, {
    variables: { input: { vacancyId } },
    fetchPolicy: 'network-only',
  })

  const userInfo = useContext(Context)

  const formatDate = applications => {
    dayjs.extend(customParseFormat)
    return applications.map(el => ({
      ...el,
      createdAt: dayjs(el.createdAt).format('DD-MM-YY'),
      // applierPhone: el.applierPhone.substring(1), -> En PRD esto debe estar comentado
    }))
  }

  const formatDateVacancy = vacancyItem => {
    dayjs.extend(customParseFormat)
    return (vacancyItem = {
      ...vacancyItem,
      createdAt: dayjs(vacancyItem.createdAt).format('DD-MM-YY'),
      updatedAt: dayjs(vacancyItem.updatedAt).format('DD-MM-YY'),
    })
  }

  useEffect(() => {
    let vacancy = get(data, 'vacancy', {})
    vacancy = formatDateVacancy(vacancy)
    setPageTitle(vacancy.title)
    setVacancy(vacancy)
  }, [data])

  useEffect(() => {
    let applications = get(dataApps, 'applications', [])
    applications = formatDate(applications)
    setApplications(applications)
  }, [dataApps])

  if (error || errorApps) return <div />

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <>
      {userInfo.role === SAURON_ROLE || userInfo.role === CONSULTANT_ROLE ? (
        <PageHeader
          title={pageTitle}
          type="vacancy"
          button={{
            text: 'Ranking',
            handleClick: () =>
              history.push(`/admin/vacancies/ranking/${vacancyId}`),
          }}
        />
      ) : (
        <PageHeader title={pageTitle} type="vacancy" />
      )}
      {loading || loadingApps ? (
        <Loading />
      ) : (
        <Container>
          <Grid className={styles.vacancyDates}>
            <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
              <Typography className={styles.vacancyDatesCreated}>
                Publicado el <span>{vacancy.createdAt}</span>
              </Typography>
              <Typography
                className={
                  vacancy.status === VISIBLE
                    ? styles.vacancyDatesClosedInactive
                    : styles.vacancyDatesClosed
                }>
                Cerrada el <span>{vacancy.updatedAt}</span>
              </Typography>
            </ProtectedComponent>
          </Grid>
          <StyledTabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            style={{ marginBottom: 20 }}>
            <StyledTab label="Postulantes" />
            {(userInfo.role === SAURON_ROLE ||
              userInfo.role === CONSULTANT_ROLE) && (
              <StyledTab label="Videos" />
            )}
            <StyledTab label="Publicación" />
          </StyledTabs>
          {selectedTab === 0 ? (
            <ApplicantsTab
              applications={applications}
              handleReload={refetch}
              handleAppReload={refetchApps}
              questions={vacancy ? vacancy.questions : []}
              vacancyStatus={vacancy?.status}
            />
          ) : selectedTab === 1 &&
            (userInfo.role === SAURON_ROLE ||
              userInfo.role === CONSULTANT_ROLE) ? (
            <VideoVotingTab
              data={vacancy ? vacancy.applicationsToVote : []}
              reload={refetch}
              reloadApp={refetchApps}
              isLoading={loading}
            />
          ) : (
            <VacancyInfoTab vacancy={data.vacancy} />
          )}
        </Container>
      )}
    </>
  )
}

export default Vacancy
