import React from 'react'

import Pages from './Pages'

const App = () => {
  return (
    <Pages />
  )
}

export default App
