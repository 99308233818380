import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedPage from '../../../components/ProtectedPage'
import { BASIC_ROLE, CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE } from '../../../userRoles'

import Create from './Create'
import Main from './Main'
import Ranking from './Ranking'
import Vacancy from './Vacancy'

const Vacancies = ({ match }) => {
  return (
    <Switch>
      <ProtectedPage allowedRoles={[BASIC_ROLE, CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
        <Route component={Main} exact path={match.url} />
        <Route component={Create} exact path={`${match.url}/create`} />
        <Route component={Ranking} exact path={`${match.url}/ranking/:vacancyId`} />
        <Route component={Vacancy} exact path={`${match.url}/:vacancyId`} />
      </ProtectedPage>
    </Switch>
  )
}

export default Vacancies
