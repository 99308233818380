import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Footer from './components/Footer'
import Header from './components/Header'

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        history.push('/admin')
      })
      .catch(error => {
        // console.log({ error })
      })
  }, [history])

  return (
    <>
      <Header />
      <Route {...rest} render={props => <Component {...props} />} />
      <Footer />
    </>
  )
}

export default PublicRoute
