import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, FormControl, TextField, Typography } from '@material-ui/core'
import { Auth } from 'aws-amplify'

import styles from './styles.module.css'

const NewPasswordForm = ({ user, userName }) => {
  const [values, setValues] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleClick = async () => {
    setLoading(true)
    setError()
    Auth.completeNewPassword(user, values.password)
      .then(() => {
        history.push('/admin')
      })
      .catch(error => {
        console.log(error)
        setError(error.message)
        if (error.code === 'InvalidPasswordException')
          setError(
            'Hubo un error, inténtelo de nuevo. Recuerda que las contraseñas son mínimo 10 caracteres. Con letras y números',
          )
        if (error.code === 'NotAuthorizedException')
          setError(
            'Ha sobrepasado el tiempo límite, vuelva a intentarlo más tarde, por favor.',
          )
        setLoading(false)
      })
  }
  return (
    <>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Cambio de contraseña
      </Typography>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          error={!!error}
          fullWidth
          id="outlined-basic-3"
          label="Correo"
          name="userName"
          type="text"
          variant="outlined"
          defaultValue={userName}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          error={!!error}
          fullWidth
          id="outlined-basic-4"
          label="Contraseña"
          name="password"
          onChange={handleChange}
          type="password"
          variant="outlined"
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          error={!!error}
          fullWidth
          id="outlined-basic"
          label="Confirme contraseña"
          name="passwordConfirmation"
          onChange={handleChange}
          type="password"
          variant="outlined"
          helperText={error}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        style={{ float: 'right' }}
        className={styles.navegationBtn}
        onClick={handleClick}>
        {loading ? 'Cargando' : 'Cambiar contraseña'}
      </Button>
    </>
  )
}

export default NewPasswordForm
