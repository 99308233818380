import isEmail from 'validator/lib/isEmail'

export const validateFields = (values, videoFile, termsAccepted, setError) => {
  const {
    name,
    phone,
    email,
    expectedSalary,
    keywords,
    position,
    areas,
  } = values

  if (!termsAccepted) {
    setError(
      'Por favor, acepte los términos, condiciones y política de privacidad',
    )
    return false
  }

  if (
    !(name && phone && email && expectedSalary && keywords && position && areas)
  ) {
    setError('Ingrese todos los campos')
    return false
  }

  if (phone.length < 10 || phone.length > 15 || isNaN(phone)) {
    setError('Ingrese un número telefónico válido')
    return false
  }

  if (!isEmail(email)) {
    setError('Correo electrónico inválido')
    return false
  }

  if (
    expectedSalary < 100 ||
    expectedSalary > 100000000 ||
    isNaN(expectedSalary)
  ) {
    setError('Ingrese un rango de renta válido')
    return false
  }

  if (areas.length === 0) {
    setError('Seleccione al menos una área de interés')
    return false
  }

  if (videoFile.current.files.length === 0) {
    setError('Por favor, agregue su CV')
    return false
  }

  if (videoFile.current.files.length)
    if (videoFile.current.files[0].size > 25e6) {
      setError('CV superó los 25 MB máximo del tamaño aceptado')
      return false
    }

  return true
}

export const filterKeywords = keywords => {
  const newItems = keywords.map(item => item.trim().toLowerCase())
  return newItems
}
