import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'

import Loading from '../../../../../components/Loading'
import MessageModal from '../../../../../components/MessageModal'
import ProtectedComponent from '../../../../../components/ProtectedComponent'
import { SAURON_ROLE } from '../../../../../userRoles'

import CompanyList from './components/CompanyList'
import EditCompanyModal from './components/EditCompanyModal'
import Options from './components/Options'

const COMPANIES = gql`
  query GetCompanies {
    companies {
      _id
      name
      plan
    }
  }
`

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($_id: ID!, $input: UpdateCompanyInput!) {
    updateCompany(_id: $_id, input: $input) {
      _id
    }
  }
`

const DELETE_COMPANY = gql`
  mutation DeleteCompany($_id: ID!) {
    deleteCompany(_id: $_id) {
      _id
    }
  }
`

const CompaniesTab = () => {
  const [filters, setFilters] = useState({
    plan: 'ALL',
    search: '',
  })
  const [maxItems, setMaxItems] = useState(5)
  const [companies, setCompanies] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [companySelected, setCompanySelected] = useState(null)
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const { loading, error, data, refetch } = useQuery(COMPANIES, {
    fetchPolicy: 'network-only',
  })
  const [updateCompany, { loading: updateLoading }] = useMutation(
    UPDATE_COMPANY,
  )
  const [deleteCompany, { loading: deleteLoading }] = useMutation(
    DELETE_COMPANY,
  )

  useEffect(() => {
    const companies = get(data, 'companies', [])
    setCompanies(companies)
  }, [data])

  useEffect(() => {
    setFilteredCompanies(
      companies.filter(el => {
        return el.name.toLowerCase().includes(filters.search.toLowerCase())
      }),
    )
  }, [companies, filters])

  const handleUpdate = async (data, callback) => {
    const input = { ...data }
    delete input._id
    delete input.__typename

    try {
      await updateCompany({
        variables: {
          _id: companySelected._id,
          input,
        },
      })
      callback()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteCompany({
        variables: {
          _id: companySelected._id,
        },
      })
      setDeleteModalOpen(false)
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = companyToEdit => {
    setCompanySelected(companyToEdit)
    setModalOpen(true)
  }

  const handlePrepareDelete = companyToDelete => {
    setCompanySelected(companyToDelete)
    setDeleteModalOpen(true)
  }

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>

  return (
    <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
      <Options
        filters={filters}
        setFilters={setFilters}
        reload={refetch}
        maxItems={maxItems}
        setMaxItems={setMaxItems}
      />
      <CompanyList
        companies={filteredCompanies}
        type={filters.plan}
        search={filters.search}
        handleEdit={handleEdit}
        handleDelete={handlePrepareDelete}
        maxItems={maxItems}
      />
      <EditCompanyModal
        open={modalOpen}
        setOpen={setModalOpen}
        company={companySelected}
        handleEdit={handleUpdate}
        loading={updateLoading}
      />
      <MessageModal
        open={deleteModalOpen}
        okText={deleteLoading ? 'Borrando...' : 'Sí'}
        cancelText="No"
        title="Borrar Empresa"
        message="¿Desea borrar esta empresa? Esta acción no podrá revertirse."
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={handleDelete}
      />
    </ProtectedComponent>
  )
}

export default CompaniesTab
