import React, { memo, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'

import MessageModal from '../../../../../../../../../../../../../../components/MessageModal'

import CommentMenu from './components/CommentMenu'

import styles from './styles.module.css'

const Comment = memo(({ text, post, posts, setPosts }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deletePost = () => {
    setPosts(posts.filter(el => el.time !== post.time))
    setOpen(false)
  }

  return (
    <Grid container direction="row">
      <div className={styles.commentSection}>
        <div className={styles.commentContainer}>
          <Typography className={styles.commentText}>{text}</Typography>
          <CommentMenu onSelect={handleClickOpen} />
        </div>
        <Typography className={styles.commentInfo}>{`${post.user} - ${new Date(
          post.time,
        ).toLocaleString()}`}</Typography>
      </div>
      <MessageModal
        open={open}
        handleConfirm={deletePost}
        handleClose={handleClose}
        okText="Eliminar"
        message="¿Estás seguro que deseas eliminar tu comentario de esta postulación?"
        title="Eliminar comentario"
      />
    </Grid>
  )
})

export default Comment
