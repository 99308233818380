import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'

import styles from './styles.module.css'

const EditCompanyModal = ({ open, setOpen, handleEdit, company, loading }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(company)
  }, [company])

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleClick = () => {
    handleEdit(values, () => {
      setOpen(false)
    })
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper className={styles.paper}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Editar empresa
        </Typography>
        <FormControl component="fieldset" className={styles.formControl}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Nombre"
            name="name"
            onChange={handleChange}
            variant="outlined"
            value={values?.name || ''}
            disabled={loading}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          component="fieldset"
          className={styles.formControl}>
          <InputLabel id="plan-select-label">Plan</InputLabel>
          <Select
            fullWidth
            label="Plan"
            labelId="plan-select-helper-label"
            onChange={handleChange}
            disabled={loading}
            name="plan"
            value={values?.plan || ''}>
            <MenuItem value={'CORP'}>Corporativo</MenuItem>
            <MenuItem value={'PYME'}>Pyme</MenuItem>
            <MenuItem value={'ZERO'}>Zero</MenuItem>
            <MenuItem value={'FULL'}>Full</MenuItem>
          </Select>
        </FormControl>
        <Button
          color="primary"
          onClick={handleClick}
          style={{ float: 'right' }}
          disabled={loading}
          variant="contained">
          Guardar cambios
        </Button>
        {loading ? <CircularProgress /> : null}
      </Paper>
    </Modal>
  )
}

EditCompanyModal.defaultProps = {
  company: {}
}

export default EditCompanyModal
