import React from 'react-router-dom'

import PageHeader from '../../components/PageHeader'

import VideoForm from './components/VideoForm'

const Video = () => {
  return (
    <>
      <PageHeader title='Tu video/entrevista de postulación' type='video' />
      <VideoForm />
    </>
  )
}

export default Video
