import React from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import ProtectedComponent from '../../../../../../../../../../components/ProtectedComponent'
import { SAURON_ROLE } from '../../../../../../../../../../userRoles'

import styles from './styles.module.css'

const ApplicantMenu = ({ handleToDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleDelete = event => {
    event.stopPropagation()
    handleToDelete()
    handleClose()
  }

  return (
    <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
      <div className={styles.menu}>
        <IconButton
          aria-label="more"
          aria-controls="apply-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="apply-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDelete}>Eliminar postulante</MenuItem>
        </Menu>
      </div>
    </ProtectedComponent>
  )
}

export default ApplicantMenu
