import React, { memo, useState } from 'react'

// import { Divider } from '@material-ui/core'
import CommentForm from './components/CommentForm'
import CommentList from './components/CommentList'

const CommentsSection = memo(
  ({ user, handleAddComment, comments, buttonLabel = 'Agregar' }) => {
    const [posts, setPosts] = useState(comments || [])

    const handleAddPost = postsUpdated => {
      const data = postsUpdated.map(r => ({
        message: r.message,
        user: r.user,
        time: r.time,
      }))

      setPosts(data)
      handleAddComment(data)
    }

    return (
      <div style={{ margin: '20px 0px' }}>
        {/* <Divider style={{ margin: '20px 0px' }} /> */}
        <CommentList posts={posts} setPosts={handleAddPost} />
        <CommentForm
          user={user}
          posts={posts}
          setPosts={handleAddPost}
          buttonLabel={buttonLabel}
        />
      </div>
    )
  },
)

export default CommentsSection
