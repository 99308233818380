import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Container, Grid, Typography } from '@material-ui/core'

import LoginModal from './components/LoginModal'

import styles from './styles.module.css'

const Banner = () => {
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()

  return (
    <div className={styles.container}>
      <Container>
        <Grid container>
          <Grid item className={styles.title} xs={12} sm={12} md={12} lg={12}>
            <Typography className={styles.textTitle}>
              Jobfitter no cambiará el mundo;
            </Typography>
            <Typography className={styles.textTitle}>
              sí lo harán los equipos que estamos
            </Typography>
            <Typography className={styles.textTitle}>
              ayudando a construir
            </Typography>
            <Typography className={styles.welcomeMessage}>
              ¡Bienvenidos!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={styles.btnContainer}>
            <Button className={styles.btn}>
              <Link className={styles.link} to="/vacancies">
                Ingresa como candidato
              </Link>
            </Button>
            {pathname === '/' ? (
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right' }}
                className={styles.loginBtn}
                onClick={() => setOpen(true)}>
                Ingresa como empresa
              </Button>
            ) : null}
          </Grid>
          <LoginModal open={open} setOpen={setOpen} />
        </Grid>
      </Container>
    </div>
  )
}

export default Banner
