import React from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '@material-ui/core'

import PageHeader from '../../../components/PageHeader'

import List from './components/List'

const Main = () => {
  const { companyId } = useParams()

  return (
    <>
      <PageHeader
        title={
          companyId === '6216ad6f9c900c00093f3381'
            ? 'Vacantes Restaurant Cassis'
            : 'Vacantes'
        }
      />
      <Container>
        <List />
      </Container>
    </>
  )
}

export default Main
