import React, { useContext, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'
import isEmail from 'validator/lib/isEmail'

import ProtectedComponent from '../../../../../../../../../components/ProtectedComponent'
import { Context } from '../../../../../../../../../context'
import { SAURON_ROLE } from '../../../../../../../../../userRoles'

import styles from './styles.module.css'

const sauronOptions = [
  { value: 'SAURON', label: 'Sauron' },
  { value: 'CONSULTANT', label: 'Consultor' },
  { value: 'MASTER', label: 'Máster' },
  { value: 'BASIC', label: 'Básico' },
]

const masterOptions = [
  { value: 'MASTER', label: 'Máster' },
  { value: 'BASIC', label: 'Básico' },
]

const COMPANIES = gql`
  query Companies {
    companies {
      _id
      name
    }
  }
`

const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      _id
      name
      email
    }
  }
`

const CreateCompanyModal = ({ open, setOpen }) => {
  const [values, setValues] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const userData = useContext(Context)
  const rolesOptions =
    userData.role === SAURON_ROLE ? sauronOptions : masterOptions

  const { data } = useQuery(COMPANIES)
  const companies = get(data, 'companies', [])

  const [createUser] = useMutation(CREATE_USER, {
    update(cache, { data: { createUser } }) {
      cache.modify({
        fields: {
          users(existingUsers = []) {
            const newUserRef = cache.writeFragment({
              data: createUser,
              fragment: gql`
                fragment NewUser on User {
                  _id
                  email
                  name
                }
              `,
            })
            return [...existingUsers, newUserRef]
          },
        },
      })
    },
  })

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleClick = async () => {
    const input = { ...values }
    if (
      !(
        input.name &&
        input.phone &&
        input.email &&
        input.position &&
        input.role
      )
    ) {
      setError('Ingrese todos los campos')
      return false
    }

    if (!isEmail(input.email)) {
      setError('Correo electrónico inválido')
      return false
    }

    if (
      input.phone.length < 10 &&
      input.phone.length > 15 &&
      isNaN(input.phone)
    ) {
      setError('Número de teléfono inválido. Verifique formato.')
      return false
    }

    setLoading(true)
    setError()

    if (!input.company) input.company = userData.company?._id

    try {
      await createUser({
        variables: { input },
      })
      setLoading(false)
      setOpen(false)
    } catch (error) {
      console.log({ error })
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper className={styles.paper}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Nuevo Usuario
        </Typography>
        <div className={styles.formWrapper}>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nombre"
              name="name"
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Correo"
              name="email"
              onChange={handleChange}
              variant="outlined"
              type="email"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Teléfono"
              name="phone"
              placeholder="Ej. +56912345678"
              helperText="Agregar +(código país) antes del número"
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
            <FormControl
              variant="outlined"
              component="fieldset"
              className={styles.formControl}>
              <InputLabel id="company-select-label">Empresa</InputLabel>
              <Select
                fullWidth
                label="Empresa"
                labelId="company-select-helper-label"
                onChange={handleChange}
                name="company"
                value={values.company || ''}>
                {companies.map(company => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ProtectedComponent>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Cargo"
              name="position"
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Área"
              name="area"
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            variant="outlined"
            component="fieldset"
            className={styles.formControl}>
            <InputLabel id="rol-select-label">Rol</InputLabel>
            <Select
              fullWidth
              label="Rol"
              labelId="rol-select-helper-label"
              onChange={handleChange}
              name="role"
              value={values.role || ''}>
              {rolesOptions.map(role => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          color="primary"
          onClick={handleClick}
          style={{ float: 'right' }}
          variant="contained">
          {loading ? 'Cargando...' : 'Crear'}
        </Button>
        <Snackbar
          open={!!error}
          onClose={() => setError('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </Paper>
    </Modal>
  )
}

export default CreateCompanyModal
