import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import PrivateRoute from '../components/PrivateRoute'
import PublicRoute from '../components/PublicRoute'
import { getClient } from '../functions/apolloClient'

import Admin from './Admin'
import CompanyVacancies from './CompanyVacancies'
import Landing from './Landing'
import Upload from './Upload'
import Vacancies from './Vacancies'
import Video from './Video'

const Pages = () => {
  return (
    <Router>
      <ApolloProvider client={getClient('public')}>
        <Switch>
          <PublicRoute component={Landing} exact path="/" />
          <PublicRoute component={Vacancies} path="/vacancies" />
          <PublicRoute component={CompanyVacancies} path="/company" />
          <PublicRoute component={Upload} path="/upload" />
          <PublicRoute component={Video} path="/video/:videoTokenId" />
        </Switch>
      </ApolloProvider>
      {/* Private routes inside /admin */}
      <ApolloProvider client={getClient('private')}>
        <Switch>
          <PrivateRoute component={Admin} path="/admin" />
        </Switch>
      </ApolloProvider>
    </Router>
  )
}

export default Pages
