import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Container, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'

import MessageModal from '../../../../components/MessageModal'
import PageHeader from '../../../../components/PageHeader'
import ProtectedPage from '../../../../components/ProtectedPage'
import VacancyForm from '../../../../components/VacancyForm'
import { Context } from '../../../../context'
import {
  CONSULTANT_ROLE,
  MASTER_ROLE,
  SAURON_ROLE,
} from '../../../../userRoles'

const CREATE_VACANCY = gql`
  mutation CreateVacancy($input: VacancyInput!) {
    createVacancy(input: $input) {
      _id
    }
  }
`

const USERS = gql`
  query Users {
    users {
      _id
      name
    }
  }
`

const Create = () => {
  const userInfo = useContext(Context)
  const { push } = useHistory()
  const [errorMessage, setErrorMessage] = useState('')
  const [users, setUsers] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [createVacancy] = useMutation(CREATE_VACANCY)
  const [values, setValues] = useState({ questions: [] })

  const { data } = useQuery(USERS)

  useEffect(() => {
    const users = get(data, 'users', [])

    setUsers(users)
  }, [data])

  const save = async () => {
    const { description, location, maxIncome, title, company, area, seniorityLevel } = values
    const areValuesCorrect = description && location && maxIncome && title && area && seniorityLevel
    const users = values.users || [userInfo._id]

    if (!areValuesCorrect) {
      setErrorMessage(
        'Por favor, ingrese todos los campos de "Información Vacante" y asegúrese de no dejar preguntas vacías.',
      )
      return
    }

    const input = {
      description,
      location,
      title,
      area,
      seniorityLevel,
      users,
      company: company ? company : userInfo.company?._id,
      maxIncome: parseFloat(maxIncome),
      questions: values.questions.map(el => {
        const { text, type, options } = el
        return {
          type,
          options,
          text,
        }
      }),
    }

    try {
      await createVacancy({ variables: { input } })
      push('/admin/vacancies')
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
    }
  }

  return (
    <ProtectedPage allowedRoles={[CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
      <PageHeader
        title="Nueva vacante"
        button={{
          text: 'Publicar Vacante',
          handleClick: () => setConfirmModal(true),
        }}
      />
      <Container>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <VacancyForm values={values} setValues={setValues} users={users} />
        <MessageModal
          open={confirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={save}
          title="Crear vacante"
          message="¿Estás seguro que deseas publicar esta vacante? No podrás editar las preguntas, y quedará visible para todo el público"
          okText="Sí"
          cancelText="No"
        />
      </Container>
    </ProtectedPage>
  )
}

export default Create
