import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

import PageHeader from '../../../components/PageHeader'

import styles from './styles.module.css'

const NotFound = () => {
  return (
    <>
      <PageHeader title="Ups, página no encontrada..." />
      <Container className={styles.container}>
        <Grid xs={12} alignItems="center">
          <ErrorIcon className={styles.icon} />
          <Typography>
            <b>
              No se encontró la página a la que desea acceder, verifique que
              tiene los permisos necesarios.
            </b>
          </Typography>
        </Grid>
      </Container>
    </>
  )
}

export default NotFound
