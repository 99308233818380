import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Candidates from './Candidates'
import Profile from './Profile'
import Profiles from './Profiles'
import Users from './Users'
import Vacancies from './Vacancies'

const Admin = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/vacancies`} />
      <Route component={Users} exact path={`${match.url}/users`} />
      <Route component={Vacancies} path={`${match.url}/vacancies`} />
      <Route component={Profiles} exact path={`${match.url}/profiles`} />
      <Route component={Candidates} exact path={`${match.url}/candidates`} />
      <Route component={Profile} exact path={`${match.url}/profile/:userId`} />
    </Switch>
  )
}

export default Admin
