import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Main from './Main'

const CompanyVacancies = ({ match }) => {
  return (
    <Switch>
      <Route component={Main} exact path={match.url} />
      <Route component={Main} exact path={`${match.url}/`} />
      <Route component={Main} exact path={`${match.url}/:companyId`} />
    </Switch>
  )
}

export default CompanyVacancies
