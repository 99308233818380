import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core'

import Logo from '../../../Logo'

import styles from './styles.module.css'

const Header = () => {
  const { push } = useHistory()

  const handleGoHome = () => {
    push('/')
  }

  return (
    <div className={styles.header}>
      <Container>
        <Grid container justify="flex-end" className={styles.options}>
          <Grid item xs={12} sm={4}>
            <Grid container justify="space-between" className={styles.navbar}>
              <NavLink
                className={styles.link}
                activeClassName={styles.activeLink}
                exact
                to="/">
                Inicio
              </NavLink>
              <NavLink
                className={styles.link}
                activeClassName={styles.activeLink}
                to="/vacancies">
                Vacantes
              </NavLink>
              <NavLink
                className={styles.link}
                activeClassName={styles.activeLink}
                to="/upload">
                Sube tu CV
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div style={{ float: 'right' }}>
              <Logo onClick={handleGoHome} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Header
