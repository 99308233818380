import React from 'react'
import { Checkbox, Grid } from '@material-ui/core'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { nanoid } from 'nanoid'

import styles from './styles.module.css'

const MultipleOptions = ({ defaultOptions, answers }) => {
  const vacancyOptions = defaultOptions.options.map(opt => ({
    inputVal: opt,
    id: nanoid(10),
    question: defaultOptions.text,
  }))
  return (
    <Grid>
      <Grid>
        {vacancyOptions.map(multiQ => (
          <div key={multiQ.id}>
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={answers.find(
                ans =>
                  ans.answer === multiQ.inputVal &&
                  multiQ.question === ans.question,
              )}
              disabled
              className={styles.checkbox}
            />
            <input
              className={styles.input}
              name="inputVal"
              value={multiQ.inputVal}
              type="text"
              disabled
              placeholder="Ingresa una opción"
            />
          </div>
        ))}
      </Grid>
    </Grid>
  )
}

MultipleOptions.defaultProps = {
  defaultProps: [],
  answers: [],
}

export default MultipleOptions
