import React from 'react'

import Banner from './components/Banner'
import ComercialContact from './components/CommercialContact'
import Steps from './components/Steps'

const Landing = () => {
  return (
    <>
      <Banner />
      <Steps />
      <ComercialContact />
    </>
  )
}

export default Landing
