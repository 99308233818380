import React, { useState } from 'react'
import { Checkbox, Grid, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import RemoveIcon from '@material-ui/icons/DeleteTwoTone'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { nanoid } from 'nanoid'

import styles from './styles.module.css'

const MultipleOptions = ({ defaultOptions = [], onChange, disabled }) => {
  const vacancyOptions = defaultOptions.map(opt => ({ inputVal: opt, id: nanoid(10) }))
  const [multiQ, setMultiQ] = useState(vacancyOptions)
  const [options, setOptions] = useState(false)

  const handleChange = event => {
    setOptions({ ...options, [event.target.name]: event.target.checked })
  }

  const handleChangeInput = (index, event) => {
    const values = [...multiQ]
    values[index][event.target.name] = event.target.value
    onChange(values.map(val => val.inputVal))
    setMultiQ(values)
  }

  const handleAddFields = () => {
    const newMulti = multiQ.concat({ inputVal: '', id: nanoid(10) })
    onChange(newMulti.map(opt => opt.inputVal))
    setMultiQ(newMulti)
  }

  const handleRemoveFields = index => {
    const values = [...multiQ]
    values.splice(index, 1)
    onChange(values.map(val => val.inputVal))
    setMultiQ(values)
  }

  return (
    <Grid>
      <Grid>
        {multiQ.map((multiQ, index) => (
          <div key={multiQ.id}>
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              disabled
              className={styles.checkbox}
              onChange={handleChange}
            />
            <input
              className={styles.input}
              name="inputVal"
              value={multiQ.inputVal}
              onChange={event => handleChangeInput(index, event)}
              type="text"
              disabled={disabled}
              placeholder="Ingresa una opción"
            />
            <IconButton
              className={styles.removeBtn}
              disabled={disabled}
              onClick={() => handleRemoveFields(index)}>
              <RemoveIcon />
            </IconButton>
          </div>
        ))}
      </Grid>
      <Grid style={{ marginLeft: '15px' }}>
        <IconButton
          size="small"
          className={styles.addBtn}
          disabled={disabled}
          onClick={() => handleAddFields()}>
          <AddIcon />
        </IconButton>
        Agrega una opción
      </Grid>
    </Grid>
  )
}

export default MultipleOptions
