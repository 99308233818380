import React, { useContext, useState } from 'react'
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core'

import MessageModal from '../../../../../../../../components/MessageModal'
import { Context } from '../../../../../../../../context'
import { SAURON_ROLE } from '../../../../../../../../userRoles'

import styles from './styles.module.css'

const rolesDictionary = {
  'SAURON': 'Sauron',
  'CONSULTANT': 'Consultor',
  'MASTER': 'Máster',
  'BASIC': 'Básico'
}

const sauronOptions = [
  { value: 'SAURON', label: 'Sauron' },
  { value: 'CONSULTANT', label: 'Consultor' },
  { value: 'MASTER', label: 'Máster' },
  { value: 'BASIC', label: 'Básico' },
]

const masterOptions = [
  { value: 'MASTER', label: 'Máster' },
  { value: 'BASIC', label: 'Básico' },
]

const SelectUserType = ({ defaultType, handleRolChange }) => {
  const [stateVacancy, setStateVacancy] = useState(defaultType)
  const [backupVacancy, setStateBackupVacancy] = useState(defaultType)
  const [modalConfirmOpen, setModalConfirm] = useState(false)
  const userData = useContext(Context)
  const rolesOptions = userData.role === SAURON_ROLE ? sauronOptions : masterOptions

  const handleStop = event => {
    event.stopPropagation()
  }

  const handleChange = event => {
    setStateBackupVacancy(event.target.value)
    setModalConfirm(true)
  }

  const handleConfirm = () => {
    handleRolChange(backupVacancy, () => {
      setModalConfirm(false)
    })
    setStateVacancy(backupVacancy)
  }
  return (
    <div className={styles.dropdown}>
      <FormControl variant='outlined'>
        <Select
          onClick={handleStop}
          name="stateVacancy"
          value={stateVacancy}
          onChange={handleChange}
          className={styles.dropdownUser}>
          {rolesOptions.map(role => (
            <MenuItem
              key={role.value}
              value={role.value}
            >
              {role.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MessageModal
        open={modalConfirmOpen}
        title='Cambiar rol'
        message={
          <>
            <span>¿Estás seguro que quieres cambiar el rol del usuario?</span>
            <div className={styles.statusChange}>
              <Typography className={styles.statusText}>Rol actual: <b>{rolesDictionary[stateVacancy]}</b></Typography>
              <Typography className={styles.statusText}>Nuevo rol: <b>{rolesDictionary[backupVacancy]}</b></Typography>
            </div>
          </>
        }
        handleClose={() => setModalConfirm(false)}
        handleConfirm={handleConfirm}
      />
    </div>
  )
}

export default SelectUserType
