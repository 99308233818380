import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { Auth } from 'aws-amplify'

export const getClient = type => {
  Auth.configure({
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    mandatorySignIn: false,
  })

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/${type}/graphql`,
  })

  const asyncAuthLink = setContext(async (operation, { headers }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()

      return {
        headers: {
          ...headers,

          Authorization: user?.signInUserSession?.idToken?.jwtToken,
        },
      }
    } catch (err) {
      console.warn(err)
      return {}
    }
  })

  const client = new ApolloClient({
    link: asyncAuthLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
