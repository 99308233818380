/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab'
import { Auth } from 'aws-amplify'

import RestoreForm from './RestoreForm'

import styles from '../styles.module.css'

const RestorePassword = ({
  setActiveResetPassword,
  setShowPasswordResetMessageCompletion,
}) => {
  const [code, setCode] = useState('')
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('info')
  const [showAlert, setShowAlert] = useState(false)
  const [userNameValidate, setUserNameValidate] = useState('')
  const [isTempBlocked, setIsTempBlocked] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const handleUserNameChange = event => {
    setUserName(event.target.value)
  }

  const handleResetPassword = () => {
    setShowAlert(true)
    if (userNameValidate !== 'success') {
      setAlertType('error')
      if (userName.length === 0)
        setAlertMessage('El correo es un campo obligatorio.')
      else setAlertMessage('Ingrese un correo válido.')
    } else {
      setCodeSent(true)
      Auth.forgotPassword(userName)
        .then(() => {})
        .catch(err => {
          if (err.code === 'LimitExceededException') {
            setIsTempBlocked(true)
            setAlertType('error')
            setAlertMessage(
              'Usuario bloqueado temporalmente por exceder límite de intentos, prueba más tarde.',
            )
          }
          if (err.code === 'NotAuthorizedException') {
            setIsTempBlocked(true)
            setAlertType('error')
            setAlertMessage(
              'La contraseña no puede ser reiniciada en el estado actual. Revise su correo si tiene alguna contraseña temporal.',
            )
          }
        })
      setAlertType('info')
      setAlertMessage(
        'Si su usuario es válido, será enviado a su correo un código de validación.',
      )
    }
  }

  const handleConfirmResetPassword = () => {
    setDisabledButton(true)
    setShowAlert(true)
    if (code.length === 0 || newPassword.length === 0) {
      setAlertType('error')
      setAlertMessage('Todos los campos son obligatorios')
      setDisabledButton(false)
      return
    }

    if (code.length < 6) {
      setAlertType('error')
      setAlertMessage('Hubo un error con su código, inténtelo nuevamente.')
      setDisabledButton(false)
      return
    }
    if (newPassword.length < 10 || confirmPassword.length < 10) {
      setAlertType('error')
      setAlertMessage(
        'Hubo un error, inténtelo de nuevo. Recuerde que las contraseñas son mínimo 10 caracteres.',
      )
      setDisabledButton(false)
      return
    }
    if (newPassword !== confirmPassword) {
      setAlertType('error')
      setAlertMessage('Las contraseñas deben ser iguales.')
      setDisabledButton(false)
      return
    }
    Auth.forgotPasswordSubmit(userName, code, newPassword)
      .then(() => {
        setShowPasswordResetMessageCompletion(true)
        setActiveResetPassword(false)
      })
      .catch(() => {
        setAlertMessage('Hubo un error, inténtelo más tarde.')
        setDisabledButton(false)
        setAlertType('error')
      })
  }
  const handleUserNameValidation = e => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const status = re.test(String(e.target.value).toLowerCase())
    status ? setUserNameValidate('success') : setUserNameValidate('error')
    status ? setError(false) : setError(true)
  }

  const handleAlreadyHavePassword = () => {
    setShowAlert(true)
    if (userNameValidate !== 'success') {
      setAlertType('error')
      if (userName.length === 0)
        setAlertMessage('El correo es un campo obligatorio.')
      else setAlertMessage('Ingrese un correo válido')
    } else {
      setCodeSent(true)
      setAlertType('info')
      setAlertMessage('Ingrese el código y su nueva contraseña')
    }
  }

  const handleBackButton = () => {
    setActiveResetPassword(false)
    setCodeSent(false)
  }

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Olvidé mi contraseña
      </Typography>
      <Collapse in={showAlert}>
        <Alert
          severity={alertType}
          variant="outlined"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false)
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {alertMessage}
        </Alert>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      </Collapse>
      {!codeSent ? (
        <>
          <FormControl component="fieldset" className={styles.formControl}>
            <TextField
              error={!!error}
              fullWidth
              id="outlined-basic-9"
              label="Correo"
              name="password"
              onChange={handleUserNameChange}
              onBlur={handleUserNameValidation}
              type="text"
              variant="outlined"
            />
          </FormControl>
          <ButtonGroup
            size="large"
            color="primary"
            aria-label="outlined primary button group"
            style={{ marginBottom: 30 }}>
            <Button disabled={disabledButton} onClick={handleResetPassword}>
              Recuperar contraseña
            </Button>
            <Button onClick={handleAlreadyHavePassword}>
              Ya tengo mi código
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <RestoreForm
          setCode={setCode}
          setConfirmPassword={setConfirmPassword}
          setNewPassword={setNewPassword}
          userName={userName}
          isTempBlocked={isTempBlocked}
        />
      )}
      <Divider style={{ marginBottom: 10 }} />
      <FormControl>
        {!disabledButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackButton}
            className={styles.navegationBtn}>
            Volver
          </Button>
        )}
      </FormControl>
      {codeSent && !isTempBlocked && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmResetPassword}
          style={{ float: 'right' }}
          className={styles.navegationBtn}>
          Crear
        </Button>
      )}
    </>
  )
}

export default RestorePassword
