import { gql, useMutation } from '@apollo/client'

const NOTIFY_APPLICATION_ACCEPTED = gql`
  mutation NotifyApplicationAccepted($_id: ID!) {
    notifyApplicationAccepted(_id: $_id) {
      MessageId
    }
  }
`
const NOTIFY_APPLICATION_REJECTED = gql`
  mutation NotifyApplicationRejected($_id: ID!) {
    notifyApplicationRejected(_id: $_id) {
      MessageId
    }
  }
`
const NOTIFY_APPLICATION_REJECTED_VIDEO = gql`
  mutation NotifyApplicationRejectedVideo($_id: ID!) {
    notifyApplicationRejectedVideo(_id: $_id) {
      MessageId
    }
  }
`

const useEmail = () => {
  const [notifyApplicationAccepted] = useMutation(NOTIFY_APPLICATION_ACCEPTED)
  const [notifyApplicationRejected] = useMutation(NOTIFY_APPLICATION_REJECTED)
  const [notifyApplicationRejectedVideo] = useMutation(
    NOTIFY_APPLICATION_REJECTED_VIDEO,
  )

  const handleNotifyAccepted = _id => {
    return notifyApplicationAccepted({
      variables: {
        _id,
      },
    })
  }

  const handleNotifyRejected = _id => {
    return notifyApplicationRejected({
      variables: {
        _id,
      },
    })
  }

  const handleNotifyRejectedVideo = _id => {
    return notifyApplicationRejectedVideo({
      variables: {
        _id,
      },
    })
  }

  return {
    handleNotifyAccepted,
    handleNotifyRejected,
    handleNotifyRejectedVideo,
  }
}

export default useEmail
