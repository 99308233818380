import React from 'react'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const SelectVacancies = ({ value, onChange }) => {
  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <div className={styles.select}>
      <InputLabel id="items-select-label">Cantidad vacantes</InputLabel>
      <Select
        labelId="items-select-label"
        id="items-select"
        value={value}
        defaultValue={5}
        name="maxItems"
        fullWidth
        onChange={event => handleChange(event)}>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={15}>15</MenuItem>
      </Select>
    </div>
  )
}

SelectVacancies.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default SelectVacancies
