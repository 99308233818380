import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './styles.module.css'

const PrivacyPolicy = () => (
  <div>
    <Typography className={styles.centerText}>
      <b><u>POLÍTICA DE PRIVACIDAD DE DATOS</u></b>
    </Typography>
    <Typography className={styles.centerText}>
      <b><u>JOBFITTER</u></b>
    </Typography>
    <Typography className={styles.paragraph}>
      La presente política de privacidad (en adelante, la “<u>Política</u>” o la “<u>Política de Privacidad</u>”) describe el tipo de información que Jobfitter recopila de los Usuarios, el mecanismo de procesamiento de información que emplea, la forma en que se utiliza dicha información, las circunstancias en que puede ser compartida, y los derechos que tienen los Usuarios sobre esa información.
    </Typography>
    <Typography className={styles.paragraph}>
      Esta Política será aplicable en caso de que el Usuario descargue, acceda o utilice Jobfitter a través de cualquiera de las Plataformas. Por el solo uso de las Plataformas de Jobfitter, se entiende que el Usuario ha leído y aceptado en todas sus partes la presente Política de Privacidad. En caso contrario, el Usuario deberá abstenerse de acceder a las Plataformas, ya sea directa o indirectamente, y de utilizar cualquier información, servicio o producto provisto por la misma.
    </Typography>
    <Typography className={styles.paragraph}>
      La Política de Privacidad constituirá un acuerdo válido y obligatorio entre el Usuario y Jobfitter SpA (en adelante, la “<u>Sociedad</u>”) en relación con los requisitos de privacidad que habrán de mantenerse respecto de la utilización por parte de la Sociedad de la información que recopile del Usuario.
    </Typography>
    <Typography className={styles.paragraph}>
      Los conceptos o palabras en mayúscula referidos en la presente Política de Privacidad tendrán los mismos significados establecidos y descritos en los Términos y Condiciones de Jobfitter.
    </Typography>
    <Typography className={styles.centerText}>
      <b>ACERCA DE JOBFITTER</b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter es una plataforma de reclutamiento que utiliza información tanto de empresas como de postulantes, para realizar recomendaciones de candidatos idóneos para una vacante específica. Jobfitter garantiza que, para efectos de dichas recomendaciones, no discriminará ni por sexo, ni por edad, ni por religión, ni por casa de estudios, ni por el nombre del colegio o del liceo o escuela y, en general, no realizará ningún tipo de discriminación que pueda afectar la vida privada u honra de los postulantes.
    </Typography>
    <Typography className={styles.paragraph}>
      <b><u>PRIMERO</u>: Información que recopilamos.</b>
    </Typography>
    <Typography className={styles.paragraph}>
      Para prestar y perfeccionar nuestros servicios, Jobfitter recopilará y almacenará la información proporcionada por los Usuarios y aquella obtenida de ellos por medio del uso de Jobfitter y sus Plataformas (en adelante, la “<u>Información</u>”).
    </Typography>
    <Typography className={styles.paragraph}>
      La Información recopilada y almacenada comprenderá:
    </Typography>
    <ol type="a">
      <li>
        <Typography className={styles.paragraph}>
          Información entregada por el Usuario al registrarse en alguna de las Plataformas de Jobfitter, o actualizar su información de cuenta, tales como, nombre, correo electrónico, teléfono, currículum vitae y videos.
        </Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          Información sobre el uso y actividad del Usuario de las Plataformas, incluyendo, pero no limitando, el tiempo, frecuencia y duración del uso de las Plataformas, número y tipo de comentarios o actualizaciones de información, registros e informes de diagnósticos, error y rendimiento, entre otros.
        </Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          Información sobre el dispositivo y la conexión, tal como, sistema operativo, dirección IP, versión del software, navegador, idioma y zona horaria.
        </Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          Información entregada por el Usuario al utilizar el servicio de atención al cliente de Jobfitter.
        </Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          Información recopilada a través de cookies.
        </Typography>
      </li>
    </ol>
    <Typography className={styles.paragraph}>
      La Información será almacenada durante la vigencia de la suscripción del Usuario en las Plataformas. En caso de término de las mismas, Jobfitter eliminará toda la Información almacenada del Usuario. El Usuario podrá dar de baja su cuenta enviando un correo electrónico a feedback@jobfitter.cl siguiendo los pasos explicados en el punto quinto de este documento.
    </Typography>
    <Typography className={styles.paragraph}>
      <b><u>SEGUNDO</u>: Cómo usamos la información</b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter utiliza la Información almacenada para operar, desarrollar, mejorar, y analizar sus servicios, pudiendo realizar estudios, mediciones, análisis estadísticos, seguimientos, y proyecciones, así como también, podrá proveer en cualquier momento servicios de análisis de información o de segmentación a terceros que sean redes o proveedores de servicios, o bien, a empresas que se encuentran en búsqueda de trabajadores para incorporar a su personal. Para dichos efectos, Jobfitter se obliga a adoptar todas las medidas que sean necesarias para asegurar que dicha información sea utilizada por las Empresas de una manera consistente con la actual Política de Privacidad.
    </Typography>
    <Typography className={styles.paragraph}>
      Sin perjuicio de lo anterior, cualquier información o documento audiovisual que pueda ser proporcionado por los Usuarios a Jobfitter, será debidamente custodiado por Jobfitter SpA y utilizado para la prestación de sus servicios. Jobfitter se obliga a no divulgar la información o documento audiovisual enviado por el Usuario en sus Plataformas ni a enviar el referido material audiovisual a terceros distintos de las Empresas sin el consentimiento previo y escrito del Usuario.
    </Typography>
    <Typography className={styles.paragraph}>
      Se deja constancia de que el tratamiento de información de los Usuarios y su uso para el perfeccionamiento de las Plataformas será realizado por Jobfitter por sí o a través de terceros, en cuyo caso, éstos últimos se someterán a las mismas obligaciones de custodia y confidencialidad de datos aquí contenidas.
    </Typography>
    <Typography className={styles.paragraph}>
      El uso de la Información de la forma señalada en esta cláusula es expresamente aceptado por el Usuario, sin perjuicio del derecho de los Usuarios de solicitar su modificación, corrección o eliminación, en cualquier tiempo, de conformidad a lo establecido en la cláusula Quinta siguiente.
    </Typography>
    <Typography className={styles.paragraph}>
      <b><u>TERCERO</u>: Confidencialidad y Seguridad de la Información. </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter ha adoptado medidas de seguridad para proteger la Información recolectada de los Usuarios, la que será mantenida de manera estrictamente confidencial.
    </Typography>
    <Typography className={styles.paragraph}>
      La Información será debidamente almacenada en plataformas de seguridad destinadas al efecto, y el acceso a las mismas estará restringido a aquellos empleados, contratistas, representantes y agentes de Jobfitter que necesitan conocer tales datos para el desempeño de sus funciones y para el desarrollo o mejora de sus servicios, impidiendo el acceso, modificación o divulgación de la Información por cualquier persona no autorizada por Jobfitter o el Usuario. Asimismo, Jobfitter declara que no divulgará ni compartirá públicamente la Información, sin el consentimiento expreso del Usuario.
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter no garantiza, y no responderá de modo alguno, ante eventuales vulneraciones por terceros no autorizados de las medidas de seguridad de las plataformas de almacenaje de información, aun cuando utilicen la información de los Usuarios en forma indebida. No obstante, en caso de que se produzca una filtración de datos o cualquier otro tipo de vulneración, Jobfitter se compromete a informar a los Usuarios afectados dentro de las siguientes 72 horas de haberse enterado del evento y a adoptar todas las medidas necesarias para la solucionar el problema.
    </Typography>
    <Typography className={styles.paragraph}>
      Sin perjuicio de lo anterior, Jobfitter podrá divulgar cierta Información, cuando de buena fe estime que es razonablemente necesaria para (i) salvar su responsabilidad legal; (ii) cuando así lo exija una orden de judicial o de una autoridad administrativa competente, de cualquier naturaleza que sea; y (iii) proteger los derechos, propiedad o seguridad de Jobfitter, de los Usuarios o de terceros.
    </Typography>
    <Typography className={styles.paragraph}>
      <b><u>CUARTO</u>: Usuarios. </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter está dirigido a personas con al menos 18 años de edad, que sean completamente capaces y competentes para entender y aceptar la presente Política de Privacidad, y no se hará responsable por la mala comprensión que puedan hacer los menores de 18 años sobre ella y sobre sus implicancias.
    </Typography>
    <Typography className={styles.paragraph}>
      <b><u>QUINTO</u>: Derechos de los Usuarios. </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Los Usuarios podrán solicitar, rectificar, modificar, actualizar o requerir la eliminación de la Información almacenada por Jobfitter. Asimismo, los Usuarios tendrán derecho a oponerse a que tratemos su información, cuando dicha oposición tiene como base el interés público, los intereses legítimos de Jobfitter o los de un tercero. Las referidas solicitudes deberán realizarse por escrito al email feedback@jobfitter.cl, con indicación de los siguientes datos:
    </Typography>
    <ol type="a">
      <li>
        <Typography>Nombre completo del Usuario.</Typography>
      </li>
      <li>
        <Typography>Email.</Typography>
      </li>
      <li>
        <Typography>Teléfono de contacto.</Typography>
      </li>
      <li>
        <Typography>País de residencia.</Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>Actividad de recopilación a la que se opone.</Typography>
      </li>
    </ol>
    <Typography className={styles.paragraph}>
      Recibida la solicitud, Jobfitter dispondrá de un plazo de 20 días para concederla o rechazarla, debiendo informar su decisión al Usuario debidamente fundamentada.
    </Typography>
    <Typography className={styles.paragraph}>
      El acceso, rectificación y remoción de datos será realizado por Jobfitter de manera gratuita, a menos que para acceder a la solicitud se requiera realizar gastos de cualquier naturaleza, los que serán cargo del Usuario.
    </Typography>
    <Typography className={styles.paragraph}>
      Una vez aceptada la solicitud de eliminación de información presentada por el Usuario, éste no podrá continuar utilizado las plataformas de Jobfitter, toda vez que la información almacenada por Jobfitter y referida en la cláusula Primera anterior es indispensable para la correcta prestación de los servicios descritos en los Términos y Condiciones de Jobfitter.
    </Typography>
    <Typography className={styles.paragraph}>
      Todo Usuario que postule a través de la Plataforma y dentro de los plazos estipulados en los avisos publicados, tendrá derecho a recibir una respuesta por parte de Jobfitter. Jobfitter se compromete a dar respuesta dentro de 4 semanas contadas desde la fecha de postulación del candidato.
    </Typography>
    <Typography className={styles.paragraph}>
      Asimismo, los Usuarios parte de la terna final tendrán derecho a solicitar feedback tras haber postulado a un proceso de selección. Para ello deberán enviar un correo electrónico al mail feedback@jobfitter.cl, indicando en el asunto nombre y nombre del cargo al que postularon.
    </Typography>
  </div>
)

export default PrivacyPolicy
