import React from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import SelectOptions from '../../../../../../../../components/SelectOptions'

import styles from './styles.module.css'

const Options = ({
  maxItems,
  setMaxItems,
  filters,
  setFilters,
  statusOptions,
  handleUnlink,
  unlinkButtonDisabled,
  isUnlinkBtnVisible,
}) => {
  const handleExpectedIncomeChange = event => {
    const { name, value } = event.target
    if (value === '') {
      setFilters({
        ...filters,
        [name]: 'ALL',
      })
      return
    }

    setFilters({
      ...filters,
      [name]: parseInt(value),
    })
  }

  const handleSelectChange = newValue => {
    setMaxItems(newValue)
  }

  const renderVacancies = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Estados de Candidatos</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.type}
          name="type"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todos</MenuItem>
          {statusOptions.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const renderRent = () => (
    <Grid item xs={6} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que pidan un monto igual o menor al monto que escribas"
        placement="top">
        <FormControl component="fieldset">
          <TextField
            label="Renta Máxima"
            type="number"
            placeholder="Ej. 1200000"
            variant="outlined"
            name="applierExpectedSalary"
            onChange={handleExpectedIncomeChange}
          />
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={12} sm={4}>
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda por nombre"
          variant="outlined"
          name="text"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  const renderButton = () =>
    isUnlinkBtnVisible ? (
      <div className={styles.button}>
        <Button
          className={styles.unlinkBtn}
          onClick={handleUnlink}
          disabled={unlinkButtonDisabled}>
          Desvincular
        </Button>
      </div>
    ) : null

  return (
    <>
      {renderButton()}
      <Grid container justify="space-between" alignItems="center">
        {renderVacancies()}
        {renderRent()}
        {renderSearch()}
        {
          <SelectOptions
            value={maxItems}
            onChange={handleSelectChange}
            name="Cantidad postulantes"
          />
        }
      </Grid>
    </>
  )
}

export default Options
