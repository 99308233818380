import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core'
import { Auth } from 'aws-amplify'

import { Context } from '../../../../context'
import {
  BASIC_ROLE,
  CONSULTANT_ROLE,
  MASTER_ROLE,
  SAURON_ROLE,
} from '../../../../userRoles'
import Logo from '../../../Logo'
import ProtectedComponent from '../../../ProtectedComponent'

import UserMenu from './components/UserMenu'

import styles from './styles.module.css'

const Navbar = ({ username }) => {
  const history = useHistory()
  const userInfo = useContext(Context)

  const handleClick = () => {
    Auth.signOut()
      .then(() => {
        history.push('/')
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleGoVacancies = () => {
    history.push('/admin/vacancies')
  }
  const handleGoProfile = () => {
    history.push(`/admin/profile/${userInfo._id}`)
  }

  return (
    <div className={styles.divNabContainer}>
      <Container xs={12}>
        <Grid container>
          <Grid item xs={4}>
            <Logo onClick={handleGoVacancies} />
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              justify="space-between"
              className={
                userInfo.role === MASTER_ROLE
                  ? styles.navbarMaster
                  : styles.navbar
              }>
              <ProtectedComponent allowedRoles={[MASTER_ROLE, SAURON_ROLE]}>
                <NavLink
                  to="/admin/users"
                  className={styles.link}
                  activeClassName={styles.activeLink}>
                  Usuarios
                </NavLink>
              </ProtectedComponent>
              <ProtectedComponent
                allowedRoles={[
                  MASTER_ROLE,
                  BASIC_ROLE,
                  CONSULTANT_ROLE,
                  SAURON_ROLE,
                ]}>
                <NavLink
                  to="/admin/vacancies"
                  className={styles.link}
                  activeClassName={styles.activeLink}>
                  Vacantes
                </NavLink>
              </ProtectedComponent>
              {/*
                <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
                <NavLink
                  to="/admin/profiles"
                  className={styles.link}
                  activeClassName={styles.activeLink}>
                  Espontáneos
                </NavLink>
              </ProtectedComponent>
              */}
              <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
                <NavLink
                  to="/admin/candidates"
                  className={styles.link}
                  activeClassName={styles.activeLink}>
                  Candidatos
                </NavLink>
              </ProtectedComponent>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <UserMenu
              username={username}
              handleLogout={handleClick}
              handleGoProfile={handleGoProfile}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Navbar
