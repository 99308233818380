import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './styles.module.css'

const TermsAndConditions = () => (
  <div>
    <Typography className={styles.centerText}>
      <b>
        <u>TÉRMINOS LEGALES Y CONDICIONES GENERALES DE USO</u>
      </b>
    </Typography>
    <Typography className={styles.centerText}>
      <b>
        <u>JOBFITTER</u>
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Los presentes términos y condiciones (en adelante, los “
      <u>Términos y Condiciones</u>”), rigen el acceso y uso que toda persona
      natural o jurídica, o representante en cualquier forma de los mismos, hace
      de Jobfitter (en adelante, “<u>Jobfitter”</u>), a través de su página web{' '}
      <u>www.jobfitter.cl</u> y del software Jobfitter (en adelante, las “
      <u>Plataformas”</u>), así como de cualquier tipo de información,
      contenido, imagen, video, audio y otra materia contenida en ella.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>PRIMERO</u>: Sobre Jobfitter.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter es una empresa que conecta personas que se encuentran en
      búsqueda de trabajo con diversas empresas que cuenten con cargos vacantes
      o postulaciones abiertas, con la finalidad de formar equipos sinérgicos,
      para lo cual, Jobfitter recomendará a las empresas referidas, candidatos
      que puedan tener una mayor probabilidad de calce con el cargo vacante.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SEGUNDO</u>: Aceptación de los Términos De Uso.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Mediante la utilización de Jobfitter, en cualquiera de sus formas, el
      Usuario acepta todos los Términos y Condiciones aquí contenidos y entiende
      que estos le son legalmente vinculantes y obligatorios. Asimismo, se
      entiende que acepta todas las demás reglas de operación, políticas y
      procedimientos que se encuentran publicados en las diversas Plataformas.
    </Typography>
    <Typography className={styles.paragraph}>
      Sin perjuicio de lo anterior, algunos servicios accesorios de Jobfitter
      pueden estar sujetos a términos y condiciones adicionales. En ese caso, el
      uso que el Usuario haga de dichos servicios estará sujeto, además, a los
      referidos términos y condiciones adicionales, que se incorporan a los
      presentes Términos y Condiciones por medio de esta referencia. En caso de
      que el Usuario no los acepte, deberá abstenerse de utilizar en cualquier
      forma dichos servicios.
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter se reserva el derecho a modificar los presentes Términos y
      Condiciones. Las referidas modificaciones serán debidamente publicadas en
      las Plataformas de Jobfitter y notificadas al Usuario a través del
      software, página web o email registrado por el Usuario. Dichos cambios,
      sean en forma de modificaciones, adiciones o eliminaciones, serán
      efectivos en la fecha que se especifique en el aviso o notificación. El
      uso continuo de Jobfitter por el Usuario, luego de la publicación y
      notificación de cualquier cambio en estos Términos de servicio significará
      que el Usuario ha aceptado dichas modificaciones.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>TERCERO</u>: USUARIO.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Para efectos de los presentes Términos y Condiciones, se entiende por
      usuario, toda persona natural o jurídica, que acceda, utilice y/o se
      registre en Jobfitter a través de cualquiera de sus Plataformas (en
      adelante, los “<u>Usuarios</u>”). Sin perjuicio de lo anterior, Jobfitter
      está dirigido a personas con al menos 18 años de edad, que sean
      completamente capaces y competentes para entender y aceptar los presentes
      Términos y Condiciones.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>CUARTO</u>: SERVICIOS.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter recomienda, a través de su plataforma, candidatos para un
      determinado cargo a empresas que se encuentren en búsqueda de personal (en
      adelante, “<u>Empresas</u>”).
    </Typography>
    <Typography className={styles.paragraph}>
      Para estos efectos de lo anterior, los Usuarios deberán seguir los
      siguientes pasos:
    </Typography>
    <ol type="1">
      <li className={styles.paragraph}>
        <Typography>
          <b>Postulación.</b> Postula en la página web <u>www.jobfitter.cl</u>,
          respondiendo lo siguiente:
        </Typography>
        <ol type="a">
          <li>Nombre</li>
          <li>Correo electrónico</li>
          <li>Teléfono</li>
          <li>Expectativas de renta</li>
        </ol>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          <b>Antecedentes laborales.</b> Opcionalmente podrás subir tu
          currículum vitae (CV). Tu CV será revisado por Jobfitter para
          determinar la idoneidad respecto al cargo al que postulas, o cualquier
          otro cargo que pueda calzar con tu experiencia. El CV que subas podría
          ser enviado a las Empresas que busquen trabajadores afines a tus
          aptitudes y requerimientos laborales.
        </Typography>
      </li>
      <li>
        <Typography className={styles.paragraph}>
          <b>Graba tu entrevista.</b> Si Jobfitter considera que tu postulación
          y CV cumplen con lo requerido por el cliente para un cargo
          determinado, te solicitaremos un video de presentación personal en el
          que deberás grabarte con tu celular respondiendo preguntas
          estandarizadas que suelen realizarse en entrevistas de trabajo. Ello
          con la finalidad de que el cliente pueda conocerte más allá de lo que
          se expresa en tu CV. Esta es una oportunidad para que puedas contarle
          a la Empresa, más detalles sobre tu experiencia, motivación y razón
          por la cual podrías ser un candidato idóneo para dicha vacante. Las
          preguntas para realizar el video, se solicitarán por correo
          electrónico, al mail registrado por ti. Una vez que tengas listo tu
          video, deberás subirlo a la plataforma en el link enviado al mismo
          correo. El video que subas será mostrado a las Empresas afines a tus
          requerimientos y utilizado por Jobfitter para obtener información
          indispensable para mejorar nuestros servicios y Plataformas. Jobfitter
          se obliga a no divulgar la entrevista en ninguna de sus Plataformas o
          a terceros distintos a las Empresas, sin la autorización previa y por
          escrito del Usuario.
        </Typography>
      </li>
      {/* <li>
        <Typography className={styles.paragraph}>
          <b>Feedback.</b> Una vez que hayas subido la información referida anteriormente, te contactaremos –en un plazo no mayor a 10 días hábiles- para entregarte información sobre el avance del proceso de búsqueda.
        </Typography>
      </li> */}
    </ol>
    <Typography className={styles.paragraph}>
      La información del Usuario será incorporada automáticamente en el software
      o Plataforma Jobfitter. En ella se incluyen algoritmos que facilitan o
      ayudan a definir el grado de idoneidad entre dicha información
      proporcionada por el candidato y el cargo al que postula.
    </Typography>
    <Typography className={styles.paragraph}>
      El Usuario será responsable de mantener su información de contacto y
      currículum vitae actualizados e informar a Jobfitter en caso de que la
      información proporcionada contenga errores, con la finalidad de que éstos
      sean subsanados a la mayor brevedad posible.
    </Typography>
    <Typography className={styles.paragraph}>
      La información personal que sea entregada por nuestros Usuarios será
      debidamente almacenada y custodiada por Jobfitter, y no será divulgada a
      terceros distintos de las Empresas, y se someterá a los términos de
      Privacidad de Datos referidos en la cláusula Sexta siguiente.
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter se reserva el derecho a ejercer las acciones legales
      correspondientes contra el Usuario, en caso de que compruebe, o tenga la
      sospecha, de que la información o antecedentes proporcionados son falsos o
      no verídicos.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>QUINTO</u>: PROPIEDAD INTELECTUAL.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      En el ejercicio de los presentes Términos y Condiciones, y en virtud de la
      relación que se suscite entre Jobfitter y sus Usuarios, se entenderá que
      todas las marcas , patentes, derechos de autor, secretos comerciales,
      diseños, obras, programas, aplicaciones computacionales y otros derechos
      de propiedad intelectual relativos a Jobfitter y sus Plataformas, son de
      propiedad de Jobfitter SpA (en adelante, la “<u>Sociedad</u>”), o de
      terceros que han debidamente licenciado a Jobfitter SpA el uso de los
      mismos (en adelante, la “<u>Propiedad Intelectual</u>”).
    </Typography>
    <Typography className={styles.paragraph}>
      De esta forma, el Usuario no deberá realizar ninguna acción que ponga en
      riesgo o pudiera poner en riesgo la Propiedad Intelectual de la Sociedad.
      Asimismo, el Usuario declara que no participará directa o indirectamente
      en cualquier procedimiento disputando la titularidad o validez de la
      Propiedad Intelectual de la Sociedad, y que ésta es y será la única y
      exclusiva titular sobre marcas, patentes, derechos de autor, secretos
      comerciales, diseños, obras, programas, aplicaciones computacionales, y en
      general cualquier derecho de propiedad industrial e intelectual, tanto
      obtenido como pendientes, relacionados con Jobfitter y sus Plataformas,
      por lo que el Usuario no llevará a cabo actos que tiendan a afectar el
      valor de las invenciones, mejoras o diseños derivados total o parcialmente
      de los servicios prestados por la Sociedad.
    </Typography>
    <Typography className={styles.paragraph}>
      El Usuario no podrá realizar actos que atenten contra el correcto
      funcionamiento de las Plataformas, ni utilizar técnicas de ingeniería
      inversa, descompilar o desensamblar el software; hacer copias del
      software; utilizar el software de manera ilegal, o transmitir información
      ilegal, amenazante, ofensiva, injuriosa, difamatoria, obscena, vulgar,
      pornográfica, profana o indecente de ningún tipo, ni siquiera imágenes o
      lenguaje de esta naturaleza por las Plataformas; infringir los derechos de
      terceros, o transmitir mensajes que constituyan, fomenten o inciten una
      conducta que constituiría un delito penal u origine responsabilidad civil;
      transmitir información, documentos u otro material que contenga un virus u
      otro componente dañino; usar cualquier marca, logotipo, imágenes, textos,
      diseño de página, formatos u otra información de propiedad exclusiva de la
      Sociedad, sin su consentimiento escrito y expreso; intentar sondear,
      escanear o evaluar la vulnerabilidad de las Plataformas, o de cualquier
      sistema o red asociados, o violar las medidas de seguridad o autenticación
      sin una autorización adecuada; y reproducir, copiar, traducir, modificar,
      adaptar, descompilar, desarmar, crear cualquier obra derivada de,
      traducción de, realce, actualización, adición, desarrollo o mejora de las
      Plataformas o de la propiedad intelectual de Jobfitter.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SEXTO</u>: USO DE DATOS.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter se obliga a resguardar la información entregada y obtenida por
      los Usuarios, y adoptar los medios y técnicas que estén a su alcance para
      evitar la pérdida, mal uso, alteración, acceso no autorizado y/o
      apropiación de estos por terceros, de acuerdo a la Política de Privacidad
      de Jobfitter, la que se entiende incorporada a los presentes Términos y
      Condiciones, para todo los efectos legales y contractuales a los que haya
      lugar.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SÉPTIMO</u>: DECLARACIONES DEL USUARIO.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      El Usuario declara, reconoce y acepta que:
    </Typography>
    <ol type="i">
      <li>
        <Typography>
          El uso de Jobfitter, en cualquiera de sus Plataformas, se efectúa
          libre y voluntariamente y bajo su única y exclusiva responsabilidad.
        </Typography>
      </li>
      <li>
        <Typography>
          Jobfitter es solo un sistema de reclutamiento de personal que
          recomienda candidatos, por lo que todas y cada una de las elecciones
          que realicen las Empresas serán de exclusiva responsabilidad de las
          mismas, y Jobfitter no podrá ser responsable, directa ni
          indirectamente, de las decisiones o elecciones que ellas efectúen.
        </Typography>
      </li>
      <li>
        <Typography>
          Toda la información proporcionada por él es verídica y no contiene
          antecedentes falsos o que puedan inducir a error o engaño de Jobfitter
          o de las Empresas.
        </Typography>
      </li>
      <li>
        <Typography>
          Será íntegramente responsable de todo daño o perjuicio ocasionado a
          Jobfitter o a las Empresas por proporcionar, mantener en su poder y/o
          difundir información o antecedentes personales o académicos falsos.
        </Typography>
      </li>
    </ol>
    <Typography className={styles.paragraph}>
      <b>
        <u>OCTAVO</u>: LEGISLACIÓN APLICABLE.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Los presentes Términos y Condiciones se regirán por la legislación
      chilena, sin perjuicio de los derechos de protección al consumidor que le
      asisten a los Usuarios en los países en los cuales van dirigidos nuestros
      servicios.
    </Typography>
  </div>
)

export default TermsAndConditions
