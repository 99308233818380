import React, { useContext } from 'react'

import { Context } from '../../context'
import NotFound from '../../Pages/Admin/NotFound'

const ProtectedPage = ({ children, allowedRoles }) => {
  const { role } = useContext(Context)
  if (!role) return <span />

  const isAllowed = allowedRoles.indexOf(role) !== -1
  return isAllowed ? children : <NotFound />
}

ProtectedPage.defaultProps = {
  allowedRoles: [],
}

export default ProtectedPage
