import React from 'react'
import {
  Divider,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core'

import Questions from './components/Questions'

import styles from './styles.module.css'

const ApplicationForm = ({ values, setValues, questions }) => {
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  return (
    <>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Nombre"
          variant="outlined"
          name="applierName"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Teléfono"
          variant="outlined"
          name="applierPhone"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Correo electrónico"
          variant="outlined"
          name="applierEmail"
          fullWidth
          onChange={handleChange}
        />
      </FormControl>
      <FormControl component="fieldset" className={styles.formControl}>
        <TextField
          id="outlined-basic"
          label="Expectativa de renta"
          variant="outlined"
          name="applierExpectedSalary"
          fullWidth
          type='number'
          placeholder='Ej: 1200000'
          onChange={handleChange}
        />
      </FormControl>

      {questions.length > 0 ? (
        <>
          <Typography className={styles.label}>
            <b>Cuestionario</b>
          </Typography>
          <Divider className={styles.divider} />

          <Questions values={values} setValues={setValues} questions={questions} />
        </>
        ) : null}
    </>
  )
}

ApplicationForm.defaultProps = {
  questions: []
}

export default ApplicationForm
