import React, { useState } from 'react'
import { Container, Divider, Grid, Link, Typography } from '@material-ui/core'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

import Logo from '../../../Logo'
import PrivacyModal from '../../../PrivacyModal'

import style from './styles.module.css'

const Footer = () => {
  const [privacyOpen, setStatePrivacy] = useState(false)

  const handleOpenPrivacyModal = () => {
    setStatePrivacy(true)
  }

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid className={style.footerUp} xs={6}>
          <Logo />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Divider className={style.footerDivider} />
        <Grid className={style.footerDown} xs={12}>
          <Grid container justify="space-between">
            <Grid item>©2022 Jobfitter</Grid>
            <Grid item className={style.privacyContainer}>
              <Typography
                className={style.privacyLabel}
                onClick={handleOpenPrivacyModal}>
                Términos, Condiciones y Política de Privacidad
              </Typography>
              <Link
                href="https://www.linkedin.com/company/jobfitter-cl/"
                target="_blank">
                <LinkedInIcon fontSize="large" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PrivacyModal open={privacyOpen} setOpen={setStatePrivacy} />
    </Container>
  )
}

export default Footer
