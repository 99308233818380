import React, { memo } from 'react'
import { FormControl, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  questionTitle: {
    marginBottom: '1rem',
  },
}))

import MultipleOptions from './components/MultipleOptions'

const Questions = memo(({ values, answers }) => {
  const classes = useStyles()
  return (
    <Paper style={{ padding: 30 }}>
      {values.map(el => {
        return (
          <div key={el._id}>
            {el.type === 'OPEN' && (
              <>
                <Typography className={classes.questionTitle}>
                  <b>{el.text}</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Respuesta"
                    variant="outlined"
                    name="text"
                    defaultValue={el.text}
                    value={
                      answers.find(ans => ans.question === el.text)?.answer ||
                      ''
                    }
                    fullWidth
                    multiline
                    disabled
                  />
                </FormControl>
              </>
            )}
            {el.type === 'MULTIPLE_CHOICE' && (
              <>
                <Typography className={classes.questionTitle}>
                  <b>{el.text}</b>
                </Typography>
                <MultipleOptions defaultOptions={el} answers={answers} />
              </>
            )}
          </div>
        )
      })}
    </Paper>
  )
})

Questions.defaultProps = {
  values: [],
  answers: [],
}

export default Questions
