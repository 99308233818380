import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import {
  Button,
  // CircularProgress,
  Container,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'

import MessageModal from '../../../components/MessageModal'
import LinearWithValueLabel from '../../../components/ProgressBar/ProgressBarWithLaber'
import useApplicationUpload from '../../../hooks/useApplicationUpload'

import styles from './styles.module.css'

const VIDEO_TOKEN = gql`
  query VideoToken($value: String!) {
    videoToken(value: $value) {
      application {
        _id
      }
    }
  }
`

const fileTypes = {
  VIDEO: 'VIDEO',
}

const VideoForm = () => {
  const { push } = useHistory()
  const { videoTokenId } = useParams()
  const videoFile = useRef(null)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const [open, setOpen] = useState(false)
  const [stateModalProgressBar, setStateModalProgressBar] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)

  const [videoToken, setVideoToken] = useState({})
  const {
    handleUploadFile,
    loading,
    progress = 0,
    setProgress,
  } = useApplicationUpload()
  const [fileSelectedButtonName, setfileSelectedButtonName] = useState(
    'Subir video/entrevista',
  )
  const { data } = useQuery(VIDEO_TOKEN, {
    variables: {
      value: videoTokenId,
    },
  })

  const closeCallback = () => {
    event.preventDefault()

    return (event.returnValue = '¡Esto cancelará la carga de archivos!')
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preventLeave = () =>
    window.addEventListener('beforeunload', closeCallback, { capture: true })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowLeave = () =>
    window.removeEventListener('beforeunload', closeCallback, { capture: true })

  useEffect(() => {
    const videoToken = get(data, 'videoToken', {})
    setVideoToken(videoToken)
  }, [data])

  useEffect(() => {
    if (loading) {
      preventLeave()
      setStateModalProgressBar(true)
    }
    if (!loading) allowLeave()
    if (!loading && progress === 100) {
      setStateModalProgressBar(false)
      setOpen(true)
      return
    }

    if (!loading && (progress > 0 || progress < 100) && stateModalProgressBar) {
      setStateModalProgressBar(false)
      setErrorModalOpen(true)
    }

    return () => allowLeave()
  }, [allowLeave, loading, preventLeave, progress, stateModalProgressBar])

  const handleSend = async () => {
    try {
      if (videoFile.current.files.length === 0) {
        setError('Por favor, seleccione su video.')
        return
      }
      setStateModalProgressBar(true)
      await handleUploadFile({
        _id: videoToken?.application?._id,
        file: videoFile.current.files[0],
        type: fileTypes.VIDEO,
      })
    } catch (error) {
      setStateModalProgressBar(false)
    }
  }

  const handleChangeFile = () => {
    setfileSelectedButtonName('Video seleccionado')
  }

  // if (loading)
  //   return (
  //     <Container>
  //       <CircularProgress />
  //     </Container>
  //   )

  return (
    <>
      <Container>
        <Typography className={styles.message}>
          Aquí podrás subir tu video/entrevista. Asegúrate de tener una conexión
          a internet estable.
        </Typography>
      </Container>
      <Grid container justify="center">
        <Grid item xs={4}>
          <Grid container justify="center">
            <div>
              <input
                id="upload-video-btn"
                type="file"
                className={styles.inputFile}
                ref={videoFile}
                onChange={handleChangeFile}
                accept="video/*,.mov,.qt"
              />
              <label htmlFor="upload-video-btn">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon />}>
                  {fileSelectedButtonName}
                </Button>
              </label>
            </div>
          </Grid>
          <Container className={styles.options}>
            <Button className={styles.confirmBtn} onClick={handleSend}>
              Subir
            </Button>
          </Container>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        onClose={() => setSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success">{success}</Alert>
      </Snackbar>
      <MessageModal
        open={open}
        handleClose={() => push('/')}
        title="Video/entrevista enviado"
        message="Tu video/entrevista ha sido enviado con éxito"
        handleConfirm={() => push('/')}
      />
      <MessageModal
        open={errorModalOpen}
        handleClose={() => {
          setProgress(0)
          setErrorModalOpen(false)
          window.location.replace('')
        }}
        title="Error"
        message="Se perdió la conexión. Refresca la página y vuelve a intentarlo. Asegúrate de tener una conexión estable a internet"
        handleConfirm={() => {
          setProgress(0)
          setErrorModalOpen(false)
          window.location.replace('')
        }}
      />
      <Modal
        open={stateModalProgressBar}
        className={styles.modal}
        onClose={() => setStateModalProgressBar(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick>
        <div className={styles.paper}>
          <p>Por favor espere a que se termine de subir su video</p>
          <LinearWithValueLabel percentage={progress}></LinearWithValueLabel>
        </div>
      </Modal>
    </>
  )
}

export default VideoForm
