import React from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const SelectOptions = ({ value, onChange, name }) => {
  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <Grid item xs={5} sm={2}>
      <FormControl className={styles.formControlStyle}>
        <InputLabel id="items-select-label">{name}</InputLabel>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={value}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={event => handleChange(event)}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
}

SelectOptions.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default SelectOptions
