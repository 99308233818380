import React from 'react'
import { Divider } from '@material-ui/core'

import MessageModal from '../MessageModal'

import PrivacyPolicy from './components/PrivacyPolicy'
import TermsAndConditions from './components/TermsAndConditions'

import styles from './styles.module.css'

const PrivacyModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MessageModal
        open={open}
        title=""
        message={
          <div className={styles.container}>
            <TermsAndConditions />
            <Divider />
            <PrivacyPolicy />
          </div>
        }
        showCancel={false}
        okText='Cerrar'
        handleConfirm={handleClose}
        handleClose={handleClose}
      />
  )
}

export default PrivacyModal
