/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import Pagination from '../../../../../../../../components/Pagination'
import { Context } from '../../../../../../../../context'
import { getPageItems } from '../../../../../../../../helpers/paginated'

import ApplicantMenu from './components/ApplicantMenu'
import ApplicantStatusSelect from './components/ApplicantStatusSelect'
import ApplicationInfo from './components/ApplicationInfo'
import CommentsSection from './components/ApplicationInfo/components/CommentsSection'

import styles from './styles.module.css'

const ApplicantsList = ({
  maxItems,
  applicantions,
  handleStop,
  statusOptions,
  handleSelectApplicant,
  applicantsSelected,
  handleSelectAllAplicants,
  handleUpdateApplication,
  isUnlinkBtnVisible,
  currentFilterType,
  questions,
  vacancyStatus,
  handleToDelete,
}) => {
  const [elements, setElements] = useState({})
  const [previousItems, setPreviousItems] = useState(null)
  const authInfo = useContext(Context)

  const setPage = useCallback(
    (page = 1) => {
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(applicantions, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [applicantions, maxItems],
  )

  const resetCurrentPage = ({ exists, currentPage, items }) => {
    !exists && currentPage !== 1 && items.length === 0
      ? setPage(1)
      : setPage(currentPage)
  }

  useEffect(() => {
    if (previousItems !== maxItems) {
      setPreviousItems(maxItems)
      setPage()
    } else resetCurrentPage(elements)
  }, [
    applicantions,
    elements.currentPage,
    elements.exists,
    maxItems,
    previousItems,
    setPage,
  ])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      {isUnlinkBtnVisible ? (
        <FormControlLabel
          className={styles.selectAllCheckbox}
          style={{ marginLeft: '1rem', marginTop: '2rem' }}
          control={<Checkbox onChange={handleSelectAllAplicants} />}
          label="Seleccionar todos"
        />
      ) : null}
      {elements.exists ? (
        elements.items.map(el => (
          <Accordion key={el._id} className={styles.applicationListItem}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`${el._id}-content`}
              id={`${el._id}-header`}>
              <div className={styles.containerText}>
                <Typography className={styles.textName}>
                  {el.status === 'TO_UNLINK' &&
                  currentFilterType === 'TO_UNLINK' ? (
                    <Checkbox
                      onChange={() => handleSelectApplicant(el._id)}
                      checked={applicantsSelected.indexOf(el._id) !== -1}
                    />
                  ) : null}
                  <b>{el.applierName}</b> - {el.createdAt}
                </Typography>
                <ApplicantStatusSelect
                  state={el.status}
                  handleStop={handleStop}
                  statusOptions={statusOptions}
                  handleStatusChange={status =>
                    handleUpdateApplication(el._id, { status })
                  }
                  applicantName={el.applierName}
                  vacancyStatus={vacancyStatus}
                />
              </div>
              <div>
                <ApplicantMenu handleToDelete={() => handleToDelete(el)} />
              </div>
            </AccordionSummary>
            <AccordionDetails className={styles.applicantInfo}>
              <ApplicationInfo
                application={el}
                user={authInfo.user}
                handleInterviewsComments={handleUpdateApplication}
                questions={questions}
              />
            </AccordionDetails>
            <Grid className={styles.addNote}>
              <CommentsSection
                buttonLabel="Agregar nota"
                user={authInfo.user}
                comments={el.notes}
                handleAddComment={commentsUpdated => {
                  handleUpdateApplication(el._id, {
                    notes: [...commentsUpdated],
                  })
                }}
              />
            </Grid>
          </Accordion>
        ))
      ) : (
        <Typography className={styles.notFoundText}>
          No se encontraron resultados
        </Typography>
      )}
      {elements.exists && renderPagination()}
    </>
  )
}

ApplicantsList.defaultProps = {
  applicantions: [],
}

export default ApplicantsList
