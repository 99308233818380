import React, { useContext, useState } from 'react'
import { Container, Grid, Tab, Tabs, withStyles } from '@material-ui/core'

import PageHeader from '../../../components/PageHeader'
import ProtectedPage from '../../../components/ProtectedPage'
import { Context } from '../../../context'
import { MASTER_ROLE, SAURON_ROLE } from '../../../userRoles'

import CompaniesTab from './components/CompaniesTab'
import UsersTab from './components/UsersTab'

const sauronTabs = [
  { id: 'usersTab', label: 'Usuarios' },
  { id: 'companiesTab', label: 'Empresas' },
]

const masterTabs = [{ id: 'usersTab', label: 'Usuarios' }]

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '80px',
      width: '100%',
      backgroundColor: '#161616',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#161616',
    fontWeight: 900,
    fontSize: '18px',
    '&:focus': {
      opacity: 1,
    },
    letterSpacing: '2px',
  },
}))(props => <Tab disableRipple {...props} />)

const Users = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const userInfo = useContext(Context)
  const tabs = userInfo.role === SAURON_ROLE ? sauronTabs : masterTabs

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <ProtectedPage allowedRoles={[MASTER_ROLE, SAURON_ROLE]}>
      <PageHeader title="Administración de usuarios" type="users" />
      <Container>
        <StyledTabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          style={{ marginBottom: 40 }}>
          {tabs.map(tab => (
            <StyledTab key={tab.id} label={tab.label} />
          ))}
        </StyledTabs>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            {selectedTab === 0 ? <UsersTab /> : <CompaniesTab />}
          </Grid>
        </Grid>
      </Container>
    </ProtectedPage>
  )
}

export default Users
