import React, { useState } from 'react'
import { Chip, Divider, Typography } from '@material-ui/core'

import styles from './styles.module.css'

const areas = [
  { label: 'Finanzas', value: 'FINANCE' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Administración', value: 'ADMINISTRATION' },
  { label: 'Logística', value: 'LOGISTICS' },
  { label: 'IT', value: 'IT' },
  { label: 'Construcción', value: 'CONSTRUCTION' },
  { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
  { label: 'RRHH', value: 'HR' },
  { label: 'Operaciones', value: 'OPERATIONS' },
  { label: 'Otro', value: 'OTHER' },
]

const OptionsAreas = ({ setValues }) => {
  const [areasSelected, setAreas] = useState([])

  const handleSelectChip = value => {
    let valuesUpdated = []

    if (areasSelected.indexOf(value) === -1)
      valuesUpdated = [...areasSelected, value]
    else valuesUpdated = areasSelected.filter(area => area !== value)

    setValues(valuesUpdated)
    setAreas(valuesUpdated)
  }

  return (
    <>
      <Typography className={styles.optionLabel}>
        <b>Elige 1 o más áreas de interés</b>
      </Typography>
      <Divider className={styles.divider} />
      <div className={styles.areasSection}>
        {areas.map(area => (
          <Chip
            key={`opt__${area.value}`}
            className={
              areasSelected.indexOf(area.value) === -1
                ? styles.area
                : styles.areaSelected
            }
            label={area.label}
            variant="outlined"
            onClick={() => handleSelectChip(area.value)}
          />
        ))}
      </div>
    </>
  )
}

export default OptionsAreas
