import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import styles from './styles.module.css'

const CREATE_APPLICATION = gql`
  mutation CreateApplication($input: ApplicationInput!) {
    createApplication(input: $input) {
      _id
    }
  }
`

const ApplyModal = ({ open, handleClose, vacancies, spontaneousToApply, callback }) => {
  const [vacancyToApply, setVacancy] = useState('')
  const [createApplication, { loading }] = useMutation(CREATE_APPLICATION)
  const [success, setSuccess] = useState('')

  const handleStop = event => {
    event.stopPropagation()
  }

  const handleSelect = event => {
    setVacancy(event.target.value)
  }

  const handleApply = async () => {
    const {
      name,
      email,
      phone,
      expectedSalary,
    } = spontaneousToApply

    try {
      await createApplication({
        variables: {
          input: {
            vacancy: vacancyToApply,
            applierName: name,
            applierPhone: phone,
            applierEmail: email,
            applierExpectedSalary: expectedSalary,
            answers: [],
            status: 'PENDING_CONTACT'
          }
        }
      })
      setSuccess('Se ha registrado al candidato con éxito.')
      callback()
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Paper className={styles.paper}>
          <Typography className={styles.title}>
            <b>Enviar a vacante</b>
          </Typography>
          <FormControl variant="outlined" className={styles.formControl}>
            <InputLabel id='apply-spontaneous-select'>Elige una vacante</InputLabel>
            <Select
              labelId='apply-spontaneous-select'
              label='Elige una vacante'
              name='vacancyToApply'
              disabled={loading}
              onClick={handleStop}
              onChange={handleSelect}
              value={vacancyToApply}
            >
              {vacancies.map(vacancy => (
                <MenuItem
                  key={vacancy._id}
                  value={vacancy._id}
                >
                  {vacancy.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider className={styles.divider} />
          <div className={styles.footer}>
            <Button
              className={styles.sendBtn}
              disabled={loading}
              onClick={handleApply}
            >
              {loading ? 'Cargando...' : 'Enviar'}
            </Button>
          </div>
        </Paper>
      </Modal>
      <Snackbar
        open={!!success}
        onClose={() => setSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">{success}</Alert>
      </Snackbar>
    </>
  )
}

ApplyModal.defaultProps = {
  vacancies: []
}

export default ApplyModal
