import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Container } from '@material-ui/core'
import { get } from 'lodash'

import Loading from '../../../../components/Loading'
import PageHeader from '../../../../components/PageHeader'

import Options from './components/Options'
import VacanciesList from './components/VacanciesList'

const VACANCIES = gql`
  query GetVacancies {
    vacancies {
      _id
      title
      location
      area
      seniorityLevel
      status
      createdAt
      updatedAt
      applications {
        _id
      }
      questions {
        _id
        text
        type
        options
      }
    }
  }
`

const DELETE_VACANCY = gql`
  mutation DeleteVacancy($_id: ID!) {
    deleteVacancy(_id: $_id) {
      _id
    }
  }
`

const Main = () => {
  const [maxITems, setMaxItems] = useState(5)
  const [filters, setFilters] = useState({
    type: 'ALL',
    search: '',
    area: 'ALL',
    seniorityLevel: 'ALL',
  })
  const { loading, data, refetch } = useQuery(VACANCIES, {
    fetchPolicy: 'network-only',
  })
  const [deleteVacancy, { loading: loadingDelete }] = useMutation(
    DELETE_VACANCY,
  )
  const vacancies = get(data, 'vacancies', [])
  const history = useHistory()
  const handleDeleteVacancy = async (id, onComplete) => {
    try {
      await deleteVacancy({ variables: { _id: id } })
      onComplete()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <PageHeader
        title="Mis vacantes"
        button={{
          text: 'Publicar vacante',
          handleClick: () => history.push('/admin/vacancies/create'),
        }}
      />
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Options
              filters={filters}
              setFilters={setFilters}
              maxITems={maxITems}
              setMaxItems={setMaxItems}
            />
            <VacanciesList
              maxITems={maxITems}
              vacancies={vacancies}
              search={filters.search}
              type={filters.type}
              area={filters.area}
              seniorityLevel={filters.seniorityLevel}
              loading={loadingDelete}
              handleDelete={handleDeleteVacancy}
              reload={refetch}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default Main
