import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import axios from 'axios'

const SPONTANEOUS_UPLOAD_URL = gql`
  query SpontaneousUploadUrl($_id: ID!, $input: SpontaneousUploadInput!) {
    spontaneousUploadUrl(_id: $_id, input: $input)
  }
`

const useSpontaneousUpload = () => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [file, setFile] = useState(null)

  const [spontaneousUploadURL, { data }] = useLazyQuery(SPONTANEOUS_UPLOAD_URL)

  useEffect(() => {
    const uploadFileToS3Effect = () =>
      axios.put(data.spontaneousUploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: progressEvent =>
          setProgress(
            Math.round(progressEvent.loaded * 100) / progressEvent.total,
          ),
      })

    if (data) uploadFileToS3Effect()
  }, [data, file])

  const handleUploadFile = async ({ _id, file }) => {
    setLoading(true)
    setFile(file)

    await spontaneousUploadURL({
      variables: {
        _id,
        input: {
          filename: file.name,
        },
      },
    })
    setLoading(false)
  }

  return { handleUploadFile, loading, progress }
}

export default useSpontaneousUpload
