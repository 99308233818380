import React from 'react'
import { Avatar, Menu, MenuItem } from '@material-ui/core'

const UserMenu = ({ handleLogout, username, handleGoProfile }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const names = username.split(' ')
  const nameAbrevation = names.reduce((accomulator, currentValue) => accomulator + currentValue.slice(0, 1), '')

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ float: 'right', cursor: 'pointer' }}>
      <Avatar aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        {nameAbrevation}
      </Avatar>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleGoProfile}>Ver mi perfil</MenuItem>
        <MenuItem onClick={handleLogout}>Desconectarse</MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu