import React from 'react'
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import styles from './styles.module.css'

const areas = [
  { label: 'Finanzas', value: 'FINANCE' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Administración', value: 'ADMINISTRATION' },
  { label: 'Logística', value: 'LOGISTICS' },
  { label: 'IT', value: 'IT' },
  { label: 'Construcción', value: 'CONSTRUCTION' },
  { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
  { label: 'RRHH', value: 'HR' },
  { label: 'Operaciones', value: 'OPERATIONS' },
  { label: 'Otro', value: 'OTHER' },
]

const seniorityLevels = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]

const Options = ({ filters, setFilters, setMaxItems, maxItems }) => {
  const renderState = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Estado</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.type}
          name="type"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todo</MenuItem>
          <MenuItem value="VISIBLE">Activa</MenuItem>
          <MenuItem value="HIDDEN">Inactiva</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={11} sm={3} justify="center" alignItems="center">
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda"
          variant="outlined"
          name="search"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  const renderItems = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="items-select-label">Cantidad de vacantes</InputLabel>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={maxItems}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={event => setMaxItems(event.target.value)}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderArea = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-area-label">Área</InputLabel>
        <Select
          labelId="area-select-label"
          id="area-select"
          name="area"
          value={filters.area}
          defaultValue={'ALL'}
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todo</MenuItem>
          {areas.map(area => (
            <MenuItem key={area.value} value={area.value}>
              {area.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const renderSenioritylevel = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-seniorityLevel-label">Cargo</InputLabel>
        <Select
          labelId="seniorityLevel-select-label"
          id="seniorityLevel-select"
          name="seniorityLevel"
          value={filters.seniorityLevel}
          defaultValue={'ALL'}
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todo</MenuItem>
          {seniorityLevels.map(seniorityLevel => (
            <MenuItem key={seniorityLevel.value} value={seniorityLevel.value}>
              {seniorityLevel.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.searchOptions}>
        {renderState()}
        {renderSearch()}
        {renderArea()}
        {renderSenioritylevel()}
        {renderItems()}
      </Grid>
    </>
  )
}

export default Options
