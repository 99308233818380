import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'

import Loading from '../../../../../components/Loading'

import Options from './components/Options'
import UsersList from './components/UsersList'

const USERS = gql`
  query Users {
    users {
      _id
      name
      role
      disabledAt
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($_id: ID!, $input: UpdateUserInput!) {
    updateUser(_id: $_id, input: $input) {
      _id
    }
  }
`

const UsersTab = () => {
  const [users, setUsers] = useState([])
  const [maxItems, setMaxItems] = useState(5)
  const [filters, setFilters] = useState({
    type: 'ALL',
    search: '',
  })
  const { loading: loadingUsers, error, data } = useQuery(USERS)
  const [updateUser] = useMutation(UPDATE_USER)

  useEffect(() => {
    const users = get(data, 'users', [])
    setUsers(users)
  }, [data])

  const handleUpdate = async (_id, data, callback) => {
    try {
      await updateUser({
        variables: {
          _id,
          input: { ...data },
        },
      })
      callback()
    } catch (error) {
      console.log(error)
    }
  }

  if (loadingUsers) return <Loading />
  if (error) return <p>Error :(</p>

  return (
    <>
      <Options
        filters={filters}
        setFilters={setFilters}
        maxItems={maxItems}
        setMaxItems={setMaxItems}
      />
      <UsersList
        maxItems={maxItems}
        users={users}
        setUsers={setUsers}
        search={filters.search}
        type={filters.type}
        handleChangeRol={handleUpdate}
      />
    </>
  )
}

export default UsersTab
