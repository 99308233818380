import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import { Auth } from 'aws-amplify'

import { Context } from '../../context'

import Footer from './components/Footer'
import Navbar from './components/Navbar'

const USER = gql`
  query User($input: UserQueryInput!) {
    user(input: $input) {
      _id
      role
      name
      company {
        _id
      }
    }
  }
`

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()
  const [authInfo, setAuthInfo] = useState({ user: '' })
  const [getUser, { data }] = useLazyQuery(USER)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        getUser({ variables: { input: { sub: user.attributes.sub } } })
        if (data) {
          setAuthInfo({ ...data.user, user: data.user.name })
          return
        }

        setAuthInfo({ user: user.attributes.name })
      })
      .catch(error => {
        console.log(error)
        // history.push('/')
      })
  }, [history, data, getUser])

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Context.Provider value={authInfo}>
        <Navbar username={authInfo.user} />
        <div style={{ paddingBottom: '11rem' }}>
          <Route {...rest} render={props => <Component {...props} />} />
        </div>
      </Context.Provider>
      <Footer />
    </div>
  )
}

export default PrivateRoute
