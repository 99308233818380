import React from 'react'
import { Box, Grid } from '@material-ui/core'

import styles from './styles.module.css'

const Steps = () => {
  return (
    <Grid container className={styles.stepsContainer}>
      <Grid  className={styles.stepTitle} xs={12} sm={12} md={12} lg={12}>
        ¿Cómo funciona?
      </Grid>
      <Box ml="8%">
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid  xs={12} sm={12} lg={3} className={styles.contentInfo}>
            <div className={`${styles.stepNumber} ${styles.stepOne}`}>1</div>
            <h2 className={styles.stepSubTitle}>Postula a una Vacante</h2>
            <p>Aplica en simples pasos. ¡No necesitas registrarte!</p>
          </Grid>

          <Grid  xs={12} sm={12} lg={3} className={styles.contentInfo}>
            <div className={`${styles.stepNumber} ${styles.stepTwo}`}>2</div>
            <h2 className={styles.stepSubTitle}>Sube tu video/entrevista</h2>
            <p>
              Si pasas a la siguiente etapa, pediremos tu video/entrevista.
            </p>
          </Grid>

          <Grid  xs={12} sm={12} lg={3} className={styles.contentInfo}>
            <div className={`${styles.stepNumber} ${styles.stepThree}`}>3</div>
            <h2 className={styles.stepSubTitle}>Entrevista con Cliente</h2>
            <p>
              Jobfitter te presentará en aquellos trabajos donde tengas mayor
              probabilidad de ser feliz.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Steps
