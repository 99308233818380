import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { CircularProgress, Container } from '@material-ui/core'
import { get } from 'lodash'

import PageHeader from '../../../../components/PageHeader'

import RankingList from './components/RankingList'
import RankingMessage from './components/RankingMessage'

const VACANCY = gql`
  query Vacancy($_id: ID!) {
    vacancy(_id: $_id) {
      _id
      title
      ranking {
        _id
        applierName
        applierPhone
        applierExpectedSalary
        status
        video
        score
        resume
        videoResults {
          vote
          userid
        }
      }
    }
  }
`

const Ranking = () => {
  const history = useHistory()
  const { vacancyId } = useParams()
  const [vacancy, setVacancy] = useState(null)
  const [isRankingVisible, setStateRankingVisible] = useState(false)

  const { loading, error, data } = useQuery(VACANCY, {
    variables: { _id: vacancyId },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    const vacancy = get(data, 'vacancy', {})
    setVacancy(vacancy)
  }, [data, loading])

  const handleShowRanking = () => {
    setStateRankingVisible(true)
  }

  if (error) return `Error! ${error.message}`

  return (
    <>
      <PageHeader
        title="Ranking"
        type="ranking"
        button={{
          text: 'Volver',
          handleClick: () => history.push(`/admin/vacancies/${vacancyId}`),
        }}
      />
      <Container>
        {!loading ? (
          isRankingVisible ? (
            <RankingList
              data={vacancy?.ranking || []}
            />
          ) : (
            <RankingMessage
              ranking={vacancy?.ranking || []}
              onAccept={handleShowRanking}
            />
          )

        ) : (<CircularProgress />)}
      </Container>
    </>
  )
}

export default Ranking