import React, { memo, useState } from 'react'
import { Button, CircularProgress, TextField } from '@material-ui/core'

import styles from './styles.module.css'

const CommentForm = memo(({ posts, setPosts, buttonLabel, user }) => {
  const [inputText, setInputText] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChange = e => {
    setInputText(e.target.value)
  }

  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()
    setTimeout(() => {
      setPosts([...posts, { message: inputText, time: new Date(), user }])
      setInputText('')
      setLoading(false)
    }, 1000)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <TextField
        label="Agrega tu comentario"
        placeholder="Agrega tu comentario..."
        value={inputText}
        name="text"
        onChange={handleChange}
        className={styles.post}
        variant="outlined"
      />
      <Button className={styles.postBtn} type="submit" disabled={loading}>
        {loading ? <CircularProgress size={20} /> : buttonLabel}
      </Button>
    </form>
  )
})

export default CommentForm
