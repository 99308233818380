import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import ModalMessage from '../../../../../../components/MessageModal'
import Pagination from '../../../../../../components/Pagination'
import ProtectedComponent from '../../../../../../components/ProtectedComponent'
import { getPageItems } from '../../../../../../helpers/paginated'
import {
  CONSULTANT_ROLE,
  MASTER_ROLE,
  SAURON_ROLE,
} from '../../../../../../userRoles'

import ApplicationModal from './components/ApplicationModal'
import VacancyMenu from './components/VacancyMenu'

import styles from './styles.module.css'

const statusEnum = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
}

const VacanciesList = ({
  maxITems,
  vacancies,
  area,
  seniorityLevel,
  search,
  handleDelete,
  type,
  loading,
  reload,
}) => {
  const history = useHistory()
  const [elements, setElements] = useState({})
  const [deleteModalOpen, setDeleteModal] = useState(false)
  const [vacancyToApply, setVacancyToApply] = useState({})
  const [applicationModalOpen, setApplicationModal] = useState(false)
  const [vacancyToDelete, setVacantyToDelete] = useState('')

  const { VISIBLE } = statusEnum

  const setPage = useCallback(
    (page = 1) => {
      const vacanciesFiltered = vacancies.filter(
        val =>
          val.title.toLowerCase().includes(search.toLocaleLowerCase()) &&
          (type === 'ALL' ? true : type === val.status) &&
          (area === 'ALL' ? true : area === val.area) &&
          (seniorityLevel === 'ALL'
            ? true
            : seniorityLevel === val.seniorityLevel),
      )

      const vacanciesOrdered = [...vacanciesFiltered].sort(
        (a, b) => b.status.length - a.status.length,
      )

      const formatDate = vacancies => {
        dayjs.extend(customParseFormat)
        return vacancies.map(el => ({
          ...el,
          createdAt: dayjs(el.createdAt).format('DD-MM-YY'),
          updatedAt: dayjs(el.updatedAt).format('DD-MM-YY'),
        }))
      }

      const vacanciesDated = formatDate(vacanciesOrdered)

      const { items, totalPages, hasNextPage, hasPreviousPage, exists } =
        getPageItems(vacanciesDated, page, maxITems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [maxITems, search, type, area, seniorityLevel, vacancies],
  )

  useEffect(() => {
    setPage()
  }, [vacancies, search, type, maxITems, area, seniorityLevel, setPage])
  const handleSelect = vacancy => {
    setVacantyToDelete(vacancy)
    setDeleteModal(true)
  }
  const handleConfirmDelete = () => {
    handleDelete(vacancyToDelete, () => {
      setDeleteModal(false)
    })
  }

  const handleSelectApply = vacancyToApply => {
    setVacancyToApply(vacancyToApply)
    setApplicationModal(true)
  }

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={3}
        className={styles.mainContentList}>
        {elements.exists ? (
          elements.items.map(el => (
            <Grid item xs key={el._id}>
              <Paper
                variant="elevation"
                square
                className={
                  el.status === VISIBLE
                    ? styles.paperList
                    : styles.paperListInactive
                }>
                <Grid
                  container
                  alignContent="center"
                  className={styles.listItem}>
                  <Grid
                    onClick={() => history.push(`/admin/vacancies/${el._id}`)}>
                    <Grid className={styles.infoVacancy}>
                      <Typography className={styles.vacTitle}>
                        {el.title}
                      </Typography>
                      <Typography className={styles.location}>
                        en {el.location}
                      </Typography>
                    </Grid>
                    <ProtectedComponent
                        allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
                        <Grid className={styles.vacancyListedDates}>
                          <Typography
                            className={styles.vacancyListedDatesCreated}>
                            Publicado el <span>{el.createdAt}</span>
                          </Typography>
                          <Typography
                            className={
                              el.status === VISIBLE
                                ? styles.vacancyListedDatesClosedInactive
                                : styles.vacancyListedDatesClosed
                            }>
                            Cerrada el <span>{el.updatedAt}</span>
                          </Typography>
                        </Grid>
                      </ProtectedComponent>
                  </Grid>
                  <Grid className={styles.options}>
                    <Typography className={styles.numberOfApplicationsLabel}>
                      {el.applications?.length} postulaciones
                    </Typography>
                    <Button
                      className={styles.btn}
                      onClick={() =>
                        history.push(`/admin/vacancies/${el._id}`)
                      }>
                      <ArrowForwardIosIcon />
                    </Button>
                    <VacancyMenu
                      className={styles.btn}
                      onSelectDelete={() => handleSelect(el._id)}
                      onSelectApply={() => handleSelectApply(el)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Typography className={styles.notFoundText}>
            No se encontraron resultados
          </Typography>
        )}
        {elements.exists && renderPagination()}
      </Grid>
      <ProtectedComponent
        allowedRoles={[CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
        <ModalMessage
          open={deleteModalOpen}
          title="Alerta: Eliminar Vacante"
          message={
            <>
              ¿Estás seguro que quieres <b>eliminar esta vacante</b>? Se perderá
              su información y la de sus postulantes.
              {loading ? <CircularProgress /> : null}
            </>
          }
          handleClose={() => setDeleteModal(false)}
          handleConfirm={handleConfirmDelete}
        />
      </ProtectedComponent>
      <ApplicationModal
        open={applicationModalOpen}
        setOpen={setApplicationModal}
        vacancyId={vacancyToApply._id}
        vacancyName={vacancyToApply.title}
        vacancyQuestions={vacancyToApply.questions}
        reload={reload}
      />
    </>
  )
}

VacanciesList.defaultProps = {
  vacancies: [],
}

export default VacanciesList
