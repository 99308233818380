import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Container, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'

import MessageModal from '../../../../../components/MessageModal'
import ProtectedComponent from '../../../../../components/ProtectedComponent'
import VacancyForm from '../../../../../components/VacancyForm'
import {
  CONSULTANT_ROLE,
  MASTER_ROLE,
  SAURON_ROLE,
} from '../../../../../userRoles'

const COMPANIES = gql`
  query GetCompanies {
    companies {
      _id
      name
    }
  }
`

const USERS = gql`
  query Users {
    users {
      _id
      name
    }
  }
`

const UPDATE_VACANCY = gql`
  mutation UpdateVacancy($_id: ID!, $input: UpdateVacancyInput!) {
    updateVacancy(_id: $_id, input: $input) {
      _id
    }
  }
`

const VacancyInfoTab = ({ vacancy }) => {
  const vacancyData = {
    ...vacancy,
    company: vacancy.company ? vacancy.company._id : '',
    users: vacancy ? vacancy.users.map(user => user._id) : [],
  }
  vacancyData.questions = vacancy
    ? vacancy.questions.map(q => ({
        ...q,
        text: q.text || '',
        type: q.type || 'OPEN',
      }))
    : []
  const [values, setValues] = useState({ ...vacancyData })
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const [modalOpen, setStateModal] = useState(false)
  const [newChanges, setStateChanges] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { data: companyData } = useQuery(COMPANIES)
  const { data: userData } = useQuery(USERS)
  const [updateVacancy] = useMutation(UPDATE_VACANCY)

  useEffect(() => {
    const companies = get(companyData, 'companies', [])
    const users = get(userData, 'users', [])
    setCompanies(companies)
    setUsers(users)
  }, [companyData, userData])

  const update = async () => {
    const {
      description,
      location,
      maxIncome,
      title,
      area,
      seniorityLevel,
      company,
      users,
      status,
    } = values
    const areValuesCorrect =
      description &&
      location &&
      maxIncome &&
      title &&
      area &&
      seniorityLevel &&
      company &&
      users &&
      status

    if (!areValuesCorrect) {
      setErrorMessage('Por favor, ingrese toda la información necesaria')
      return
    }

    const input = {
      description,
      location,
      title,
      area,
      seniorityLevel,
      company,
      users,
      status,
      maxIncome: parseFloat(maxIncome),
      questions: values.questions.map(el => {
        const { text, type, options } = el
        return {
          type,
          options,
          text,
        }
      }),
    }

    try {
      await updateVacancy({ variables: { _id: vacancy._id, input } })
      setStateModal(false)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
    }
  }
  const handleInputChange = inputsValues => {
    setStateChanges(true)
    setValues(inputsValues)
  }
  return (
    <Container>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <VacancyForm
        values={values}
        setValues={handleInputChange}
        companies={companies}
        users={users}
        editButtonDisabled={newChanges}
        handleEdit={() => setStateModal(true)}
      />
      <ProtectedComponent
        allowedRoles={[CONSULTANT_ROLE, MASTER_ROLE, SAURON_ROLE]}>
        <MessageModal
          open={modalOpen}
          title="Editar Vacante"
          message="¿Desea editar esta vacante?"
          okText="Sí"
          cancelText="No"
          handleClose={() => setStateModal(false)}
          handleConfirm={update}
        />
      </ProtectedComponent>
    </Container>
  )
}

export default VacancyInfoTab
