import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import CreateCompanyModal from './components/CreateCompanyModal'

import styles from './styles.module.css'

const CompanyHeader = ({
  filters,
  setFilters,
  reload,
  maxItems,
  setMaxItems,
}) => {
  const [open, setOpen] = useState(false)

  const renderPlan = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Plan</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.plan}
          name="plan"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }>
          <MenuItem value="ALL">Ver todas</MenuItem>
          <MenuItem value="CORP">Corporativo</MenuItem>
          <MenuItem value="PYME">Pyme</MenuItem>
          <MenuItem value="ZERO">Zero</MenuItem>
          <MenuItem value="FULL">Full</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={6} sm={5}>
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda"
          variant="outlined"
          name="search"
          fullWidth
          onChange={event =>
            setFilters({
              ...filters,
              [event.target.name]: event.target.value,
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  const renderItems = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="items-select-label">Cantidad empresas</InputLabel>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={maxItems}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={event => setMaxItems(event.target.value)}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )

  const renderButton = () => (
    <Grid item xs={12} sm={2}>
      <Button
        variant="contained"
        className={styles.btnCreate}
        onClick={() => setOpen(true)}>
        Nueva empresa
      </Button>
    </Grid>
  )

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        {renderPlan()}
        {renderSearch()}
        {renderItems()}
        {renderButton()}
      </Grid>
      <CreateCompanyModal
        open={open}
        setOpen={setOpen}
        handleComplete={reload}
      />
    </>
  )
}

export default CompanyHeader
