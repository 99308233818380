export const statusOptions = [
  {
    value: 'PENDING_CONTACT',
    label: 'Por Contactar',
    nextStatus: [
      { value: 'PENDING_CONTACT', label: 'Por Contactar' },
      { value: 'TO_UNLINK', label: 'Por Desvincular' },
      { value: 'VIDEO_REQUESTED', label: 'Video Solicitado' },
    ],
  },
  {
    value: 'VIDEO_REQUESTED',
    label: 'Video Solicitado',
    nextStatus: [
      { value: 'VIDEO_REQUESTED', label: 'Video Solicitado' },
      { value: 'VIDEO_SENT', label: 'Video Recibido' },
      { value: 'TO_UNLINK', label: 'Por Desvincular' },
    ],
  },
  {
    value: 'VIDEO_SENT',
    label: 'Video Recibido',
    nextStatus: [
      { value: 'VIDEO_SENT', label: 'Video Recibido' },
      { value: 'TO_UNLINK', label: 'Por Desvincular' },
      { value: 'APPLICANT_PRESENTED', label: 'Candidato Presentado' },
    ],
  },
  {
    value: 'APPLICANT_PRESENTED',
    label: 'Candidato Presentado',
    nextStatus: [
      { value: 'APPLICANT_PRESENTED', label: 'Candidato Presentado' },
      { value: 'VIDEO_SENT', label: 'Video Recibido' },
      { value: 'HIRED', label: 'Contratado' },
    ],
  },
  {
    value: 'HIRED',
    label: 'Contratado',
    nextStatus: [{ value: 'HIRED', label: 'Contratado' }],
  },
  {
    value: 'TO_UNLINK',
    label: 'Por Desvincular',
    nextStatus: [
      { value: 'TO_UNLINK', label: 'Por Desvincular' },
      { value: 'PENDING_CONTACT', label: 'Por Contactar' },
      { value: 'VIDEO_REQUESTED', label: 'Video Solicitado' },
      { value: 'VIDEO_SENT', label: 'Video Recibido' },
      { value: 'UNLINKED', label: 'Desvinculado' },
    ],
  },
  {
    value: 'UNLINKED',
    label: 'Desvinculado',
    nextStatus: [{ value: 'UNLINKED', label: 'Desvinculado' }],
  },
]
